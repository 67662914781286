import get from "lodash/get";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, withHandlers, withProps, withState } from "recompose";
import lifecycle from "recompose/lifecycle";
import { change, initialize, touch } from "redux-form";

import {
  UserDataSelectors,
  withAppLoader,
  withLocalServiceState,
  withNotifier,
} from "@dpdgroupuk/mydpd-app";
import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

import { Fields, SCAN_SHIPMENT_FORM, ShipmentEntity } from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import { withImportBanner } from "~/features";
// import withShipmentAsyncValidation from "~/pages/Shipment/hocs/withShipmentAsyncValidation";
import { ShipmentModels } from "~/models";
import { editTotalNumberOfPackages } from "~/models/validators/additionalValidations";
import { ReferenceActions } from "~/redux";
import { getValue } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";

import withEditFailedImportLoad from "../Shipment/hocs/withEditFailedImportLoad";
import withEditShipmentLoad from "../Shipment/hocs/withEditShipmentLoad";
import withShipmentData from "../Shipment/hocs/withShipmentData";
import { ShipmentActions } from "../Shipment/redux";
import Shipment from "../Shipment/Shipment";
import * as ScanningActions from "./redux/actions";
import * as ScanningModels from "./redux/models";
import * as ScanningSelectors from "./redux/selectors";

export default compose(
  withState("customerRef1", "setCustomerRef1"),
  withState("autofocusTotalPackages", "setAutofocusTotalPackages"),
  withState("autofocusCustomerRef1", "setAutofocusCustomerRef1"),
  withState("clearButtonDisabled", "setClearButtonDisabled"),
  withState("printButtonsDisabled", "setPrintButtonsDisabled"),
  withState("csvService", "setCsvService"),
  withRouter,
  withNotifier,
  withProps(() => ({
    pageConfig: {
      formName: SCAN_SHIPMENT_FORM,
      pageTitle: S.CREATE_SHIPMENT,
      pageMiddleText: S.ALL_FIELDS_MARKED_REQUIRED,
    },
  })),
  withLocalServiceState,
  connect(
    (state, { localServiceState, pageConfig }) => ({
      disabledFields: ScanningSelectors.getDisabledFields(state, pageConfig),
      requiredFields: ScanningSelectors.getRequiredFields(state, pageConfig),
      isImportServiceAvailable: localServiceState.isRunning,
      storageDate: UserDataSelectors?.getItem(state.app, "date"),
      shipment: ScanningSelectors.getShipment(state),
      allowedFields: ScanningSelectors.getAllowedFields(state, pageConfig),
    }),
    (dispatch, { notifier }) => ({
      createProduct: (product, packageNumber) =>
        dispatch(ScanningActions.createParcelProduct(product, packageNumber)),
      initializeScanShipmentForm: initialValues =>
        dispatch(initialize(SCAN_SHIPMENT_FORM, initialValues)),
      touchFields: fields => dispatch(touch(SCAN_SHIPMENT_FORM, ...fields)),
      fetchNetworks: notifier.runAsync(
        query => dispatch(ReferenceActions.fetchNetworks(query)),
        { entityName: S.NETWORKS }
      ),
      onClearShipment: () => dispatch(ScanningActions.clearShipment()),
    })
  ),
  withHandlers({
    shipmentAdditionalValidation: () => props => [
      () => {
        if (!props.shipment?.stagingShipmentId) {
          return editTotalNumberOfPackages(
            props,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
          );
        }
      },
      () => {
        if (!props.shipment?.stagingShipmentId) {
          editTotalNumberOfPackages(
            props,
            ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
            S.CONSIGNMENT_DIRECTION.INBOUND
          );
        }
      },
    ],
  }),
  // NOTE: withShipmentAsyncValidation contains only ref1 validation redundant for this page, reintroduce with other validations later if needed
  // withShipmentAsyncValidation,
  withShipmentData,
  withEditShipmentLoad,
  withEditFailedImportLoad,
  connect(
    (state, { pageConfig }) => ({
      shipmentDetailsReview: ScanningSelectors.getShipmentDetailsReview(
        state,
        pageConfig
      ),
    }),
    (
      dispatch,
      {
        pageConfig,
        notifier,
        abortController,
        setAbortController,
        banner,
        inboundServiceErrorBannerId,
        outboundServiceErrorBannerId,
        setCustomerRef1,
        setClearButtonDisabled,
        setAutofocusCustomerRef1,
        setPrintButtonsDisabled,
        profile,
        preferences,
        validateOnUniqueShipmentRef1,
        createShipmentValues,
      }
    ) => ({
      handleSubmitAddressbook: dispatch(
        ScanningActions.handleSubmitAddressbook({ pageConfig, notifier })
      ),
      onClickClear: (autofocus = true, customerRef1) => {
        setAbortController(abortController.abort());
        dispatch(ScanningActions.clearScanShipmentPage(customerRef1));
        setAbortController(new AbortController());
        banner.hideById(inboundServiceErrorBannerId);
        banner.hideById(outboundServiceErrorBannerId);
        banner.hideByType(BANNERS_TYPES.ALERT);
        setClearButtonDisabled(true);
        setPrintButtonsDisabled(true);

        if (!customerRef1) {
          setCustomerRef1("");
        }
        if (autofocus) {
          setAutofocusCustomerRef1(true);
        }
      },
      onPageUnmount: () => dispatch(ScanningActions.unmountScanningPage()),
      resetCustomerRef1: () => {
        dispatch(change(pageConfig.formName, Fields.CUSTOMER_REF_1, ""));
        setCustomerRef1("");
        setAutofocusCustomerRef1(true);
        setClearButtonDisabled(false);
      },
      onOutboundCountryChange: async selection => {
        const countryCode = selection?.value || S.GB;
        const deliveryInstructions = profile.useMyDpdAccountSettings
          ? getValue(profile, "defaultInformation", "").toUpperCase()
          : "";

        dispatch(ReferenceActions.setActiveOutboundService({}));
        dispatch(
          initializeForm(pageConfig.formName, {
            ...(deliveryInstructions && {
              [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION]:
                deliveryInstructions,
            }),
            [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]:
              ShipmentModels.getDefaultTotalWeight(
                profile,
                preferences,
                countryCode,
                getValue(
                  createShipmentValues,
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
                  ""
                )
              ),
            [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]:
              ShipmentModels.getDefaultNumberOfParcels(
                preferences,
                countryCode
              ),
          })
        );
        // https://it.dpduk.live/version/customer-shipping/sprint-2.9/diag_w0IY9V6GAqAADNEM.html
        // run async validation manually since redux form doesn't give new value in time
        // async background task
        validateOnUniqueShipmentRef1(
          getValue(
            createShipmentValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
          )
        ).catch(() => {
          // ignore error because it's shown in the popup inside validateOnUniqueShipmentRef1
        });
      },
    })
  ),
  withHandlers({
    printShipment:
      ({
        dispatch,
        banner,
        shipment: selectedShipment,
        confirmPrintHandler,
        pageConfig,
        overlay,
        onClickCopyNumber,
        onClickClear,
        setConsignmentBannerId,
      }) =>
      async printNow => {
        let consignmentNumber;
        let shipment;

        try {
          overlay.show();

          if (selectedShipment.stagingShipmentId) {
            shipment = await dispatch(
              ShipmentActions.createShipment({
                formName: pageConfig.formName,
              })
            );
          } else {
            shipment = await dispatch(
              ShipmentActions.updateShipment(
                {
                  formName: pageConfig.formName,
                },
                selectedShipment?.shipmentId
              )
            );
          }

          consignmentNumber = get(
            shipment.consignments,
            "[0].consignmentNumber"
          );

          if (printNow) {
            await confirmPrintHandler(shipment);
          }
        } finally {
          overlay.hide();
        }

        // @see: https://geopost.jira.com/browse/CSHIP-6406
        setConsignmentBannerId(
          banner.showByType(BANNERS_TYPES.INFO, {
            message: `${S.SHIPMENT_SAVED_SUCCESSFULLY} ${consignmentNumber}`,
            closable: true,
            showIcon: true,
            actions: [
              {
                text: S.COPY_NUMBER,
                handleClick: () => onClickCopyNumber(consignmentNumber),
              },
            ],
          })
        );

        onClickClear();

        return shipment;
      },
  }),
  connect(
    null,
    (
      dispatch,
      {
        notifier,
        banner,
        loadEditShipment,
        loadEditFailedImport,
        preferences,
        onClickPrint,
        printShipment,
        customerPrefs,
        setCustomerRef1,
        customerRef1,
        setAutofocusTotalPackages,
        setClearButtonDisabled,
        setAutofocusCustomerRef1,
        resetCustomerRef1,
        setPrintButtonsDisabled,
        overlay,
        onClickClear,
        snackbar,
      }
    ) => ({
      onBlurScanReference: notifier.runAsync(
        async (event, value) => {
          setAutofocusCustomerRef1(false);
          value && setCustomerRef1(value.toUpperCase());

          if (value && value.toUpperCase() !== customerRef1) {
            onClickClear(false, value.toUpperCase());
            try {
              overlay.show();
              // @see https://it.dpduk.live/version/customer-shipping/sprint-2.10/diag_1GjuBsGGAqCIa1Yq.html?id=1671537201259
              const shipment = await dispatch(
                ScanningActions.fetchShipmentByScanReference({
                  [Fields.CUSTOMER_REF_1]: value,
                })
              );

              if (!shipment) {
                return snackbar.showAlert({
                  message: S.SHIPMENT_NOT_FOUND,
                  displayTime: SHOW_ALERT_DISPLAY_TIME,
                });
              }

              if (shipment.stagingShipmentId) {
                const { stagingShipmentId, failureDetails, ...rest } = shipment;
                // @see https://it.dpduk.live/version/customer-shipping/sprint-2.10/diag_R4ak_sGGAqCIa1_P.html?id=1671537234746
                await loadEditFailedImport({
                  shipmentDetails: rest,
                  failureDetails,
                  stagingShipmentId,
                });
                setClearButtonDisabled(false);
                setPrintButtonsDisabled(false);
              } else {
                // @see https://it.dpduk.live/version/customer-shipping/sprint-2.10/diag_s2CrkcGGAqCIayqP.html?id=1671537218270
                await loadEditShipment({ ...shipment, isScanned: true });

                if (
                  shipment.isVoided ||
                  shipment.isPrinted ||
                  !shipment.allowPrintShipment
                ) {
                  let message = "";

                  if (ShipmentModels.isOldShipment(shipment)) {
                    message =
                      S.UNABLE_TO_RETURN_SHIPMENT_OLDER_THAN_10_DAYS_IN_PAST;
                  } else {
                    message = shipment.isVoided
                      ? S.SHIPMENT_IS_VOIDED
                      : shipment.isPrinted
                      ? S.SHIPMENT_IS_PRINTED
                      : S.NO_PERMISSIONS_TO_PRINT_SHIPMENT;
                  }

                  banner.showByType(BANNERS_TYPES.ALERT, {
                    message,
                    closable: true,
                    showIcon: true,
                    actions: [],
                  });
                  resetCustomerRef1();
                } else if (preferences.shippingDefaults.printOnScan) {
                  await onClickPrint(true, printShipment);
                } else if (
                  !ShipmentModels.isShipmentEditable(shipment, customerPrefs)
                ) {
                  banner.showByType(BANNERS_TYPES.ALERT, {
                    message: S.NO_PERMISSIONS_TO_CREATE_UPDATE_SHIPMENT,
                    closable: true,
                    showIcon: true,
                    actions: [],
                  });
                  resetCustomerRef1();
                } else {
                  setAutofocusTotalPackages(true);
                  setClearButtonDisabled(false);
                  setPrintButtonsDisabled(false);
                }
              }
            } catch (error) {
              setClearButtonDisabled(false);
              throw error;
            } finally {
              overlay.hide();
            }
          }
        },
        { entityName: S.SHIPMENT_ENTITY }
      ),
    })
  ),
  withAppLoader({
    loadFn: async ({
      setAbortController,
      fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef,
      preferences,
      initWithShipmentDate,
      initializeScanShipmentForm,
      setClearButtonDisabled,
      setAutofocusCustomerRef1,
      setPrintButtonsDisabled,
    }) => {
      // @see https://it.dpduk.live/version/customer-shipping/sprint-2.10/diag_P4UctkGGAqCIeGQ_.html?id=1671540247392
      setAbortController(new AbortController());
      setClearButtonDisabled(true);
      setPrintButtonsDisabled(true);
      setAutofocusCustomerRef1(true);

      const { profiles, countries } =
        await fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef();

      const initialValues = ScanningModels.getScanShipmentInitialValues(
        profiles[0],
        countries,
        preferences
      );

      await initializeScanShipmentForm(initialValues);

      initWithShipmentDate();
    },
  }),
  withHandlers({
    onBackClick: ({ banner, history }) => {
      banner.hideAll();
      history.goBack();
    },
  }),
  withImportBanner,
  lifecycle({
    componentWillUnmount() {
      this.props.onPageUnmount();
    },
  })
)(Shipment);

import api from "~/apis/api";
import { IMPORTS, SHIPPING_IMPORTS } from "~/apis/basePaths";

export const createImportJobId = (templateId, body) =>
  api.post({
    path: `${IMPORTS}`,
    query: {
      templateId,
    },
    body,
  });

export const runImportJobId = (jobId, data) =>
  api.post({
    path: `${IMPORTS}/${jobId}`,
    body: data,
  });

export const getImportResultByJobId = async (jobId, params) => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/${jobId}/logs`,
    query: params,
  });
  return data;
};

export const getFailedImportResultByJobId = async jobId => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/${jobId}/errors`,
  });
  return data;
};

export const getImportReceiptByJobId = async (jobId, query = {}) => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/${jobId}/receipts`,
    query,
  });
  return data;
};

export const getImportLabelsByJobId = async (jobId, query = {}) => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/${jobId}/labels`,
    query,
  });

  return data;
};

export const completeWorkstationJob = jobId =>
  api.put({
    path: `${SHIPPING_IMPORTS}/${jobId}/prints`,
  });

export const getImportReceiptsByShipmentIds = async shipmentIds => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/receipts`,
    query: {
      filterBy: "shipmentIds",
      shipmentIds,
    },
  });
  return data;
};

export const getReceiptByShipmentDate = async shipmentDate => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/receipts`,
    query: {
      filterBy: "shipmentDate",
      shipmentDate,
    },
  });
  return data;
};

export const getJobsForWorkstation = async workstationId => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/prints/${workstationId}`,
  });
  return data;
};

import { ImportsModels } from "~/pages/Imports/models";
import { TemplateActions, TemplateSelectors } from "~/redux/template";
import { UmsActions } from "~/redux/ums";

export const fetchInitialAppData = () => (dispatch, getState) =>
  Promise.all([
    dispatch(UmsActions.fetchPreferences()),
    dispatch(UmsActions.fetchCustomer()),
    dispatch(UmsActions.fetchCustomerPrefs()),
    dispatch(UmsActions.fetchCustomerServices()),
    dispatch(UmsActions.fetchAdvanceConfiguration()),
    dispatch(UmsActions.fetchShippingSettings()),
    dispatch(UmsActions.fetchCustomerAddress()),
    dispatch(UmsActions.fetchSecuritySettings()),
    dispatch(TemplateActions.fetchCacheableShipmentImportTemplates()).then(
      () => {
        const shipmentImportTemplates =
          TemplateSelectors.getShipmentImportTemplates(getState());
        const shipmentTemplateId = ImportsModels.getDefaultTemplateId(
          shipmentImportTemplates
        );
        if (shipmentTemplateId) {
          return dispatch(
            TemplateActions.fetchCacheableShipmentImportTemplateById(
              shipmentTemplateId
            )
          );
        }
      }
    ),
    dispatch(TemplateActions.fetchCacheableShipmentReceiptTemplate()),
  ]);

import { useCallback } from "react";

import Joi from "joi";
import { get } from "lodash";
import { isEmpty } from "lodash/lang";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, propTypes, reduxForm, stopSubmit } from "redux-form";

import { createCancelableRequest } from "@dpdgroupuk/fetch-client";
import * as StringUtil from "@dpdgroupuk/mydpd-app/lib/utils/string";
import { REGEX } from "@dpdgroupuk/mydpd-joi-validator";
import { FormControl, withSnackbar } from "@dpdgroupuk/mydpd-ui";

import Autocomplete from "~/components/Autocomplete";
import {
  getSearchAddressDetails,
  getSearchAddressErrors,
} from "~/components/SearchAddress/selectors";
import {
  DELIVERY_DETAILS_SEARCH_FORM,
  Fields,
  FilterFields,
  FilterOptionsList,
  SEARCH_CRITERIA_VALUE,
  ShipmentEntity,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import {
  ADDRESS_BOOK,
  ADDRESS_BOOKS,
  SEARCH,
  UPPERCASE,
} from "~/constants/strings";
import { ShipmentModels } from "~/models";
import searchAddress from "~/models/validators/searchAddress";
import { ShipmentActions } from "~/pages/Shipment/redux";
import { AddressBookActions } from "~/redux";
import {
  getErrorMessage,
  isIgnoredError,
  searchCriteriaFields,
} from "~/utils/error";
import createValidator from "~/utils/joiReduxForm";

const getHelperText = field =>
  field &&
  `${SEARCH} ${FilterOptionsList.find(
    ({ value }) => value === field
  ).label.toLowerCase()}`;

const SearchAddress = ({
  onFieldEntry,
  formValues,
  onSelectionChange,
  onChangeAddressBookFilter,
  onSearch,
  addressbookType,
  onChange,
  disabledFields,
  form,
}) => {
  const searchCriteriaField = get(formValues, "searchCriteriaField");

  const getSearchQuery = useCallback(
    value => ({
      searchCriteria: ShipmentModels.getSearchCriteria(
        searchCriteriaField,
        value
      ),
      type: addressbookType,
    }),
    [searchCriteriaField, addressbookType]
  );

  const formatBeforeSearch = useCallback(
    value =>
      searchCriteriaField === Fields.POSTCODE &&
      value.replace(/\s/g, "").match(REGEX.GB_POSTCODE_PATTERN)
        ? StringUtil.addSpaceToUKPostcode(value)
        : value.trim(),
    [searchCriteriaField]
  );

  const onSelectionChangeHandler = useCallback(
    values =>
      onSelectionChange({
        ...values,
        searchCriteria: searchCriteriaField,
      }),
    [searchCriteriaField, onSelectionChange]
  );

  const shouldSearch = useCallback(
    value => {
      const error = Joi.validate(
        { searchCriteriaField, searchCriteriaValue: value },
        searchAddress
      ).error;

      return !error;
    },
    [searchCriteriaField]
  );

  const labelKey = useCallback(
    option => formValues.searchCriteriaValue || option.searchData,
    [formValues.searchCriteriaValue]
  );

  return (
    <Row>
      <Col lg={5}>
        <Field
          component={FormControl.Dropdown}
          label={ADDRESS_BOOK}
          name={FilterFields.SEARCH_CRITERIA_FIELD}
          values={FilterOptionsList}
          onChange={onChangeAddressBookFilter}
          onBlur={onFieldEntry}
          helperText
          textTransform={UPPERCASE}
          disabled={disabledFields[form]}
        />
      </Col>
      <Col lg={7}>
        <Field
          component={Autocomplete}
          label={SEARCH}
          name={FilterFields.SEARCH_CRITERIA_VALUE}
          onBlur={onFieldEntry}
          helperText={getHelperText(formValues.searchCriteriaField)}
          id={FilterFields.SEARCH_CRITERIA_VALUE}
          onSearch={onSearch}
          labelKey={labelKey}
          optionLabelMapper={option => option.searchData}
          onSelectionChange={onSelectionChangeHandler}
          withAutocomplete
          maxLength={45}
          type="search"
          minLength={1}
          formatBeforeSearch={formatBeforeSearch}
          getSearchQuery={getSearchQuery}
          shouldSearch={shouldSearch}
          onChange={onChange}
          disabled={disabledFields[form]}
        />
      </Col>
    </Row>
  );
};

SearchAddress.propTypes = {
  ...propTypes,
  onSelectionChange: PropTypes.func,
  form: PropTypes.string.isRequired,
  formErrors: PropTypes.object,
  onChange: PropTypes.func,
};

export default compose(
  withSnackbar,
  connect(
    (state, { form, initialSearchAddressValues }) => ({
      initialValues: initialSearchAddressValues,
      formValues: getSearchAddressDetails(form)(state),
      formErrors: getSearchAddressErrors(form)(state),
    }),
    (
      dispatch,
      { snackbar, form, pageConfig, selectedAddressBook, disabledFields }
    ) => ({
      onChangeAddressBookFilter: () => dispatch(stopSubmit(form), {}),
      onSearch: createCancelableRequest(async (query, options) => {
        try {
          const {
            data: { results },
          } = await dispatch(
            AddressBookActions.fetchAddressBooks(query, options)
          );
          return results;
        } catch (err) {
          if (!isIgnoredError(err)) {
            if (searchCriteriaFields.includes(err?.errors?.[0]?.fieldName)) {
              dispatch(
                stopSubmit(form, {
                  [SEARCH_CRITERIA_VALUE]: err?.errors?.[0]?.message,
                })
              );
            } else {
              snackbar.showAlert({
                message: getErrorMessage(err, ADDRESS_BOOKS),
                displayTime: SHOW_ALERT_DISPLAY_TIME,
              });
            }
          }
        }
      }),
      onChange: value => {
        if (isEmpty(value)) {
          dispatch(ShipmentActions.clearAddressBook(form));
          form === DELIVERY_DETAILS_SEARCH_FORM &&
            !isEmpty(selectedAddressBook) &&
            disabledFields[
              ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
            ] &&
            dispatch(ShipmentActions.resetReference1(pageConfig));
        }
      },
    })
  ),
  reduxForm({
    destroyOnUnmount: false,
    validate: createValidator(searchAddress),
  })
)(SearchAddress);

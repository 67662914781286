import {
  cloneDeep,
  get,
  has,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isNull,
  omit,
  pick,
  set,
  values,
} from "lodash";
import { reduce } from "lodash/collection";
import moment from "moment";

import * as StringUtil from "@dpdgroupuk/mydpd-app/lib/utils/string";
import {
  INVOICE_REQUIRED,
  REQUIRED_TYPE,
  SHIPMENT_TYPES,
} from "@dpdgroupuk/mydpd-enums";

import { isImporterBusiness } from "~/components/EditImporterModal/models";
import * as DATE_FORMAT from "~/constants/dateFormats";
import {
  AddExtraLabelEntity,
  ADDRESS,
  COLLECTION_DETAILS,
  CONTACT_DETAILS,
  CREATE_SHIPMENT_FORM,
  DELIVERY_DETAILS,
  EDIT_FAILED_SHIPMENT_FORM,
  EXPORTER_DETAILS,
  Fields,
  IMPORTER_DETAILS,
  INBOUND_CONSIGNMENT,
  INBOUND_CONSIGNMENT_UPPERCASE,
  INVOICE,
  NOTIFICATION_DETAILS,
  OUTBOUND_CONSIGNMENT,
  ProductEntity,
  SEARCH_TYPE,
  ShipmentEntity,
} from "~/constants/forms";
import { SHIPPING_REF_MAX_LENGTH } from "~/constants/numbers";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";
import { AddressModels } from "~/models/address";
import { CardModels } from "~/models/card";
import { CustomsModels } from "~/models/customs";
import { InvoiceModels } from "~/models/invoice";
import { LiabilityModels } from "~/models/liability";
import { ServiceModels } from "~/models/service";
import { formatISODate, isSunday, isWeekend } from "~/utils/date";
import createValidator from "~/utils/joiReduxForm";
import { roundToDecimal } from "~/utils/number";
import {
  flatPathToObject,
  flattenEntityRoutes,
  getValue,
  mapArrayToObjectByKey,
  omitNilValues,
  replaceEmptyStringToUndefined,
  toUppercaseValues,
} from "~/utils/object";
import { stringifyQuery } from "~/utils/query";
import { mapErrorsToReduxForm } from "~/utils/reduxForm";
import { formatString, truncateString } from "~/utils/string";

import postcodeValidation from "../validators/additionalPostcodeValidation";
import { addressBookSchema } from "../validators/addressBookSchema";

export const shipmentTypes = {
  noType: {
    value: SHIPMENT_TYPES.NO_TYPE.toString(),
    label: "No",
  },
  swapIt: {
    value: SHIPMENT_TYPES.SWAP_IT.toString(),
    label: "Swap it",
  },
  reverseIt: {
    value: SHIPMENT_TYPES.REVERSE_IT.toString(),
    label: "Reverse it",
  },
};

export const setNetworkShipmentType = ({ shipment, network }) => {
  let shipmentType = SHIPMENT_TYPES.NO_TYPE;
  if (!isEmpty(shipment.inboundConsignment) && !isEmpty(network)) {
    if (network?.product?.productCode === "1^12") {
      shipmentType = SHIPMENT_TYPES.SWAP_IT;
    } else {
      shipmentType = SHIPMENT_TYPES.REVERSE_IT;
    }
  }

  return {
    ...shipment,
    shipmentType,
  };
};

export const getShipmentTypeLabel = shipmentTypeValue => {
  const shipmentType = Object.values(shipmentTypes).find(
    ({ value }) => value === shipmentTypeValue
  );
  return shipmentType ? shipmentType.label : "";
};

export const isSwapItShipmentType = shipmentType =>
  parseInt(shipmentType) === SHIPMENT_TYPES.SWAP_IT;

export const isReverseItShipmentType = shipmentType =>
  parseInt(shipmentType) === SHIPMENT_TYPES.REVERSE_IT;

export const isNoneShipmentType = shipmentType =>
  parseInt(shipmentType) === SHIPMENT_TYPES.NO_TYPE;

export const isSwapItOrReverseItShipmentType = shipmentType =>
  isSwapItShipmentType(shipmentType) || isReverseItShipmentType(shipmentType);

export const getMinShipmentDate = enableWeekend => {
  const currentDate = new Date();
  const isWeekendDay = isWeekend(currentDate);
  return !enableWeekend && isWeekendDay
    ? moment()
        .day(isSunday(currentDate) ? 1 : 8) // Next Monday
        .format(DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
    : moment().format(DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT);
};

export const getPreferredShipmentDate = (
  date,
  enableWeekend = false,
  defaultShipmentDate
) => {
  let minDate = getMinShipmentDate(enableWeekend);

  if (
    !date &&
    defaultShipmentDate &&
    moment(defaultShipmentDate, DATE_FORMAT.ISO_DATE_FORMAT).isSameOrAfter(
      moment(minDate, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
    )
  ) {
    minDate = moment(defaultShipmentDate, DATE_FORMAT.ISO_DATE_FORMAT).format(
      DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT
    );
  }

  return moment(date, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT).isSameOrAfter(
    moment(minDate, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
  )
    ? moment(date, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT).format(
        DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT
      )
    : minDate;
};

export const getAvailableDateRange = enableWeekend => {
  const minDate = moment(
    getMinShipmentDate(enableWeekend),
    DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT
  );
  const currentDate = new Date();
  const isWeekendDay = isWeekend(currentDate);

  return {
    minDate: minDate.toDate(),
    maxDate: minDate.add(isWeekendDay && !enableWeekend ? 5 : 7, "d").toDate(),
  };
};

export const isCountryDomestic = countryCode =>
  countryCode === S.GB || countryCode === S.IE;

// @see:https://it.dpduk.live/it/diagram/diag_3UNAbiGGAqCIZHup.html?id=1668700474296
export const isRequiredForService = selectedService =>
  [REQUIRED_TYPE.MANDATORY, REQUIRED_TYPE.OPTIONAL].includes(
    get(selectedService, "prodRequired")
  ) && get(selectedService, "commodityRequired") === REQUIRED_TYPE.MANDATORY;

// @see: https://it.dpduk.live/it/diagram/diag_7NG2I16GAqAADLol.html
export const isAdditionalCommCodeCheckRequired = (
  selectedCountry,
  selectedService
) => {
  if (!selectedService) return false;

  const serviceCode = parseInt(ServiceModels.getServiceCode(selectedService));
  const networkCode = parseInt(ServiceModels.getNetworkCode(selectedService));
  const countryKey = get(selectedCountry, "countryKey");
  const commodityRequired = get(selectedService, "commodityRequired");

  return (
    serviceCode === 9 ||
    (countryKey === S.IE &&
      (networkCode === 11 || commodityRequired === REQUIRED_TYPE.MANDATORY))
  );
};

export const getSearchAddressInitialValues = preferences => {
  const userType = get(
    preferences,
    "shippingDefaults.shipmentAddressSearchType",
    1
  );
  return {
    searchCriteriaField: SEARCH_TYPE[userType],
    searchCriteriaValue: "",
  };
};

export const isValidDeliveryContactSection = errors =>
  isEmpty(
    pick(errors, [
      ...values(
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      ),
      ...values(
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
          .NOTIFICATION_DETAILS
      ),
    ])
  );

export const isValidDeliveryDetailsSection = errors =>
  isEmpty(
    pick(errors, [
      ...values(ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS),
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION,
    ])
  );

export const isIrelandDirect = (countryCode, product) =>
  countryCode === S.IE && ServiceModels.removeBusinessUnit(product) === "18";

export const isValidServiceDetailsFields = errors =>
  isEmpty(
    pick(errors, [
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
    ])
  );

export const isServiceDropdownDisabled = ({
  syncErrors,
  submitErrors,
  services,
  productCode,
  selectedCountry,
}) =>
  services.length === 0 ||
  isIrelandDirect(selectedCountry.countryKey, productCode) ||
  !isValidServiceDetailsFields(syncErrors) ||
  submitErrors.outboundConsignment;

export const isCountryGB = countryCode => countryCode === S.GB;

export const getCurrencyLabel = currency =>
  S.CURRENCY_TO_SYMBOL[currency] || currency;

export const isValidPackageDetailsSection = (errors = {}, submitErrors = {}) =>
  isEmpty(
    pick({ ...errors, ...submitErrors }, [
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
      ShipmentEntity.SHIPMENT_DATE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
    ])
  );

export const isProductDropdownDisabled = (syncErrors, submitErrors, products) =>
  products.length === 0 ||
  !isValidServiceDetailsFields(syncErrors) ||
  submitErrors.outboundConsignment;

export const getRoundedHelperText = (selectedCountry = {}) =>
  isCountryDomestic(selectedCountry.countryKey)
    ? S.ROUND_UP_TO_THE_NEAREST_WHOLE_NUMBER
    : S.ROUNDED_UP;

export const isValidReturnDetailsSection = errors =>
  isEmpty(
    pick(errors, [
      ...values(ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS),
      ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_INSTRUCTIONS,
    ])
  );

export const isValidReturnPackageDetailsSection = errors =>
  isEmpty(
    pick(errors, [
      ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
      ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      ShipmentEntity.INBOUND_CONSIGNMENT.PRODUCT,
      ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE,
      ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_1,
      ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_2,
      ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_3,
    ])
  );

export const getSearchCriteria = (searchCriteriaField, searchCriteriaValue) => {
  const value = searchCriteriaValue.trim();

  if (value && !searchCriteriaField) {
    searchCriteriaField = Fields.SHORT_NAME;
  }

  return searchCriteriaField && value
    ? stringifyQuery({ [searchCriteriaField]: value }, true)
    : null;
};

export const getPackageDetails = ({
  shipmentDate,
  // omit deliveryDetails from packageDetails
  // eslint-disable-next-line no-unused-vars
  [OUTBOUND_CONSIGNMENT]: { deliveryDetails, ...rest } = {},
} = {}) => ({ ...rest, shipmentDate });

export const getReturnPackageDetails = ({
  shipmentDate,
  // omit deliveryDetails from packageDetails
  // eslint-disable-next-line no-unused-vars
  [INBOUND_CONSIGNMENT]: { deliveryDetails, ...rest } = {},
} = {}) => ({ ...rest, shipmentDate });

export const getDeliveryDetails = formValues =>
  get(formValues, `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}`, {});

export const getReturnDetails = formValues =>
  get(formValues, `${INBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}`, {});

export const getInvoiceExporterDetails = formValues =>
  get(formValues, `${INVOICE}.${EXPORTER_DETAILS}`, {});

export const getInvoiceImporterDetails = formValues =>
  get(formValues, `${INVOICE}.${IMPORTER_DETAILS}`, {});

// @see:https://it.dpduk.live/it/diagram/diag_JGINhl6GAqAADAAt.html
export const getGenerateCustomsDataMessage = (
  service,
  generateCustomsData,
  { prefsInvoiceSettings: { createInvoice } }
) => {
  if (
    generateCustomsData ||
    get(service, "proforma", REQUIRED_TYPE.NEEDLESS) === REQUIRED_TYPE.NEEDLESS
  ) {
    return;
  }

  if (
    isNull(generateCustomsData) &&
    createInvoice === INVOICE_REQUIRED.SELECT_EACH_TIME
  ) {
    return S.CUSTOMS_DATA_IS_REQUIRED;
  }

  if (isBoolean(generateCustomsData) && !generateCustomsData) {
    return S.CUSTOMS_INVOICE_IS_REQUIRED;
  }

  if (get(service, "proforma") === REQUIRED_TYPE.OPTIONAL) {
    return S.CUSTOMS_DATA_IS_REQUIRED;
  }
};

export const getDirectShipmentMessage = (service, countryCode) => {
  if (
    !isEmpty(service) &&
    (isIrelandDirect(countryCode, service.product.productKey) ||
      (service.proforma === REQUIRED_TYPE.NEEDLESS &&
        service.prodRequired === REQUIRED_TYPE.MANDATORY &&
        ServiceModels.removeBusinessUnit(service.networkKey) !== "20"))
  ) {
    return S.PLEASE_REMEMBER_TO_PLACE_ALL_PACKAGES;
  }
};

export const isValidInvoiceSection = errors => isEmpty(errors.invoice);

export const isOnlyInvoiceImporterSectionInvalid = errors =>
  isValidInvoiceSection(omit(errors, ["invoice.importerDetails"])) &&
  !isEmpty(errors?.invoice?.importerDetails);

export const isShippingCost = field =>
  field === ShipmentEntity.INVOICE.SHIPPING_COST;

export const isProductUnitValue = field => field === ProductEntity.UNIT_VALUE;

export const isProductUnitWeight = field => field === ProductEntity.UNIT_WEIGHT;

export const mapBoundQueryErrorsToReduxForm = (error, fieldPaths) => {
  const errors = {};
  error.errors &&
    error.errors.forEach(({ message, fieldName, fieldPath }) => {
      fieldName &&
        message &&
        fieldPaths[fieldPath] &&
        set(errors, [fieldPaths[fieldPath]], message);
    });

  return errors;
};

export const isEmptyConsignmentErrors = (errors, fields) =>
  isEmpty(pick(errors, fields));

export const getDefaultTotalWeight = (
  profile,
  preferences,
  countryCode,
  networkCode
) => {
  const domestic = isCountryDomestic(countryCode);
  const userTotalWeight = domestic
    ? Math.max(get(preferences, "shippingDefaults.domestic.domWeight", 0), 1)
    : Math.max(
        get(preferences, "shippingDefaults.international.intWeight", 0),
        1
      );

  return ServiceModels.roundTotalWeight(
    String(
      (!profile.useMyDpdAccountSettings && profile.defaultWeight) ||
        userTotalWeight
    ),
    countryCode,
    networkCode,
    true
  );
};

export const getDefaultNumberOfParcels = (preferences, countryCode) => {
  const domestic = isCountryDomestic(countryCode);

  return String(
    domestic
      ? Math.max(
          get(preferences, "shippingDefaults.domestic.domNumItems", 0),
          1
        )
      : Math.max(
          get(preferences, "shippingDefaults.international.intNumItems", 0),
          1
        )
  );
};

export const updateReturnTotalWeight = (values, totalWeight) => {
  const newValues = cloneDeep(values);
  set(newValues, "inboundConsignment.totalWeight", totalWeight);

  return newValues;
};

export const updateTotalWeight = (values, totalWeight) => {
  const newValues = cloneDeep(values);
  if (newValues.outboundConsignment) {
    newValues.outboundConsignment.totalWeight = totalWeight;
  }
  if (newValues.invoice) {
    newValues.invoice.totalWeight = totalWeight;
  }
  return isSwapItShipmentType(newValues.shipmentType)
    ? updateReturnTotalWeight(newValues, totalWeight)
    : newValues;
};

export const updateTotalExtraWeight = (values, totalWeightOfNewParcels) => {
  const newValues = cloneDeep(values);

  set(
    newValues,
    AddExtraLabelEntity.TOTAL_NEW_PARCELS_WEIGHT,
    totalWeightOfNewParcels
  );

  return newValues;
};

export const isOutboundCountry = field =>
  field ===
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE;

export const isInboundCountry = field =>
  field ===
  ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE;

export const getOutboundTotalWeight = values =>
  get(values, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT, "");

export const getInboundTotalWeight = values =>
  get(values, ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT, "");

export const isOutboundTotalWeight = field =>
  field === ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT;

export const isInboundTotalWeight = field =>
  field === ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT;

export const isLabelExtraWeight = field =>
  field === AddExtraLabelEntity.TOTAL_NEW_PARCELS_WEIGHT;

export const isOutboundNumberOfParcels = field =>
  field === ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS;

export const updateCreateShipmentFormFields = ({
  formSection,
  profile,
  address,
  deliveryInstructions,
  contactDetails,
  notificationDetails,
  shippingRef1,
  shippingRef2,
  shippingRef3,
  addressBookId,
}) => ({
  [formSection]: {
    ...(addressBookId && { addressBookId }),
    [Fields.DELIVERY_INSTRUCTION]:
      get(profile, "defaultInformation") || deliveryInstructions,
    [DELIVERY_DETAILS]: {
      [ADDRESS]: AddressModels.getAddressField(address),
      ...(contactDetails && {
        [CONTACT_DETAILS]: {
          [Fields.CONTACT_NAME]: contactDetails?.contactName,
          [Fields.TELEPHONE]: contactDetails?.telephone,
        },
      }),
      ...(notificationDetails && {
        [NOTIFICATION_DETAILS]: {
          [Fields.EMAIL]: notificationDetails?.email,
          [Fields.MOBILE]: notificationDetails?.mobile,
        },
      }),
    },
    ...(!isEmpty(shippingRef1)
      ? {
          [Fields.SHIPPING_REF_1]: shippingRef1,
          [Fields.SHIPPING_REF_2]: shippingRef2,
          [Fields.SHIPPING_REF_3]: shippingRef3,
        }
      : {}),
  },
});

export const getDefaultShippingRef1 = (preferences, countryCode) => {
  const domestic = isCountryDomestic(countryCode);

  return domestic
    ? getValue(
        preferences,
        "shippingDefaults.domestic.domReferenceText",
        ""
      ).toUpperCase()
    : getValue(
        preferences,
        "shippingDefaults.international.intReferenceText",
        ""
      ).toUpperCase();
};

export const getDefaultUniqueShippingRef1 = (
  countryCode,
  uniqueShippingRef1 = "",
  { preferences, shippingSettings }
) => {
  const defaultShippingRef1 = getDefaultShippingRef1(preferences, countryCode);
  if (shippingSettings.allocateSenders && !!uniqueShippingRef1) {
    return defaultShippingRef1 + uniqueShippingRef1;
  }
  return defaultShippingRef1;
};

// @see https://it.dpduk.live/it/diagram/diag_fqzw3B6C48kIGOfl.html?id=1623140408534
export const getCollectionDetails = (
  formValues,
  profile,
  customerAddress,
  customer,
  countries
) => {
  const collectionDetails = get(
    formValues,
    "outboundConsignment.collectionDetails"
  );

  if (collectionDetails && !isEmpty(collectionDetails)) {
    return collectionDetails;
  }

  const address = AddressModels.getOriginUserCollectionAddress({
    customer,
    profile,
    customerAddress,
    countries,
  });
  const contactDetails = {
    contactName: truncateString(
      formatString(get(customer, "customercontact", ""), 35)
    ),
    telephone: truncateString(
      formatString(get(customer, "phonenumber", "")),
      15
    ),
    email: truncateString(formatString(get(customer, "email", "")), 100),
  };

  return {
    address: omitNilValues(AddressModels.truncateAddress(address)),
    contactDetails: omitNilValues(contactDetails),
  };
};

export const isProductsRequired = service =>
  ![REQUIRED_TYPE.MANDATORY, REQUIRED_TYPE.OPTIONAL].includes(
    get(service, "proforma")
  ) && get(service, "prodRequired") === REQUIRED_TYPE.MANDATORY;

const parseInvoiceValue = value => {
  switch (value) {
    case "G":
      return true;
    case "C":
      return false;
    case "S":
      return null;
    default:
      return null;
  }
};

const getCustomsDataPreSelectedValue = (profile, preferences) => {
  const valueFromProfile = parseInvoiceValue(
    get(profile, "invExportInvoiceRequired")
  );
  const valueFromShippingDefaults = parseInvoiceValue(
    get(preferences, "prefsInvoiceSettings.createInvoice")
  );

  return valueFromProfile === null
    ? valueFromShippingDefaults
    : valueFromProfile;
};

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.6/diag_F_RVHF6GAqAADOKk.html?id=1650536806775
export const getGenerateCustomsDataSettings = (
  service,
  preferences,
  profile
) => {
  switch (get(service, "proforma")) {
    case REQUIRED_TYPE.MANDATORY:
      return {
        dropdownVisible: true,
        dropdownDisabled: true,
        preSelectedValue: true,
      };
    case REQUIRED_TYPE.OPTIONAL: {
      return {
        dropdownVisible: true,
        preSelectedValue: getCustomsDataPreSelectedValue(profile, preferences),
      };
    }
    default:
      return {
        dropdownVisible: false,
        preSelectedValue: null,
      };
  }
};

export const getQueryShipment = (
  formValues,
  selectedAddressBook,
  selectedReturnAddressBook,
  user
) => {
  const domesticShipment = omit(formValues, [
    Fields.CUSTOMER_REF_1,
    Fields.INBOUND_CONSIGNMENT,
    ShipmentEntity.SHIPMENT_TYPE,
    ShipmentEntity.INVOICE.TOTAL_WEIGHT,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DISPLAY_WEIGHT,
    ShipmentEntity.INVOICE.NUMBER_OF_PARCELS,
    isEmpty(selectedAddressBook) &&
      `${Fields.OUTBOUND_CONSIGNMENT}.${Fields.ADDRESS_BOOK_ID}`,
    isEmpty(selectedReturnAddressBook) &&
      `${Fields.INBOUND_CONSIGNMENT}.${Fields.ADDRESS_BOOK_ID}`,
    "activeField",
  ]);

  if (
    get(
      domesticShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
    ) === S.GB
  ) {
    const outboundConsignmentPostcode = getValue(
      domesticShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ""
    );
    set(
      domesticShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      StringUtil.addSpaceToUKPostcode(outboundConsignmentPostcode)
    );
  }

  set(
    domesticShipment,
    ShipmentEntity.SHIPMENT_DATE,
    formatISODate(domesticShipment.shipmentDate)
  );

  if (getValue(formValues, Fields.GENERATE_CUSTOMS_DATA)) {
    set(
      domesticShipment,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
      isImporterBusiness(
        get(formValues, ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS)
      )
    );
  }

  const parcels = get(
    domesticShipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS
  );

  if (!isEmpty(parcels)) {
    set(
      domesticShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS,
      parcels.filter(({ products }) => !isEmpty(products))
    );
  }

  // @see https://it.dpduk.live/version/customer-shipping/sprint-2.7/diag_AplIggGGAqCIajUS.html?id=1652793322146
  if (
    user.includeCustomerCode &&
    selectedAddressBook &&
    !get(domesticShipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_6)
  ) {
    set(
      domesticShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_6,
      selectedAddressBook.shortName?.slice(0, SHIPPING_REF_MAX_LENGTH)
    );
  }

  if (isSwapItOrReverseItShipmentType(formValues.shipmentType)) {
    if (
      get(
        domesticShipment,
        ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
      ) === S.GB
    ) {
      const inboundConsignmentPostcode = getValue(
        domesticShipment,
        ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        ""
      );
      set(
        domesticShipment,
        ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        StringUtil.addSpaceToUKPostcode(inboundConsignmentPostcode)
      );
    }

    return {
      ...domesticShipment,
      inboundConsignment: {
        liability: domesticShipment.outboundConsignment.liability,
        ...omit(formValues.inboundConsignment, [
          isReverseItShipmentType(formValues[ShipmentEntity.SHIPMENT_TYPE])
            ? Fields.DELIVERY_DESCRIPTION
            : "",
        ]),
        collectionDetails: pick(
          domesticShipment.outboundConsignment.deliveryDetails,
          ["address", "contactDetails"]
        ),
      },
    };
  }

  return domesticShipment;
};

export const getCollectionDetailsForInbound = formValues => ({
  address: omitNilValues(
    get(
      formValues,
      `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${ADDRESS}`,
      {}
    )
  ),
  contactDetails: omitNilValues(
    get(
      formValues,
      `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${CONTACT_DETAILS}`,
      {}
    )
  ),
});

export const getQueryForServices = (
  values,
  servicesRelatedFields,
  collectionDetails,
  profileCode,
  formSection
) => {
  const { shipmentType, ...fieldValues } = pick(values, servicesRelatedFields);
  const {
    totalWeight,
    numberOfParcels,
    deliveryDetails = {},
  } = replaceEmptyStringToUndefined(get(fieldValues, formSection));

  return {
    deliveryDetails: AddressModels.transformServiceAddress(
      deliveryDetails?.address
    ),
    collectionDetails: AddressModels.transformServiceAddress(
      collectionDetails?.address
    ),
    profileCode,
    shipmentType,
    numberOfParcels,
    totalWeight: totalWeight?.toString(),
  };
};

export const isValidReturnServiceDetailsFields = errors =>
  isEmpty(
    pick(errors, [
      ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
    ])
  );

const blackList = [
  "outboundConsignment.collectionDetails",
  "invoice.exporterDetails",
  "invoice.importerDetails",
];

const replaceList = [
  {
    key: "inboundConsignment.collectionDetails",
    value: "outboundConsignment.deliveryDetails",
  },
];

const filterErrors = errors =>
  errors.filter(
    e => e.fieldPath && !blackList.find(path => e.fieldPath.startsWith(path))
  );

const changeErrorsFieldPath = errors =>
  errors.map(error => {
    const mapObj = replaceList.find(({ key }) =>
      error.fieldPath.startsWith(key)
    );

    return {
      ...error,
      fieldPath: mapObj
        ? error.fieldPath.replace(mapObj.key, mapObj.value)
        : error.fieldPath,
    };
  });

export const getShipmentFormErrors = errors => {
  if (isArray(errors)) {
    const filtered = filterErrors(errors);
    const changed = changeErrorsFieldPath(filtered);
    return changed.length
      ? flatPathToObject(mapErrorsToReduxForm(changed))
      : null;
  }

  return errors;
};

export const getNetworkQueryFromShipment = (
  shipment,
  profileCode,
  formSection
) => {
  const {
    shipmentType,
    [formSection]: {
      deliveryDetails,
      collectionDetails,
      numberOfParcels,
      totalWeight,
    },
  } = pick(shipment, [
    formSection,
    ShipmentEntity.PROFILE_CODE,
    ShipmentEntity.SHIPMENT_TYPE,
  ]);

  return {
    deliveryDetails: AddressModels.transformServiceAddress(
      deliveryDetails?.address
    ),
    collectionDetails: AddressModels.transformServiceAddress(
      collectionDetails?.address
    ),
    profileCode,
    shipmentType: shipmentType.toString(),
    // NOTE: blur this field convert value to string
    // as result serviceQuery and newServiceQuery will be different and launch redundant fetching services
    numberOfParcels: numberOfParcels.toString(),
    totalWeight: totalWeight.toString(),
  };
};

export const setupInitialGenerateCustomsData = data => {
  const { shipment, selectedOutboundNetwork, preferences, profile } = data;
  const { dropdownVisible, dropdownDisabled } = getGenerateCustomsDataSettings(
    selectedOutboundNetwork,
    preferences,
    profile
  );

  let generateCustomsData = null;

  if (isEmpty(shipment.invoice) && dropdownVisible) {
    generateCustomsData = false;
  }

  if (
    dropdownVisible &&
    (!isEmpty(shipment.invoice) ||
      selectedOutboundNetwork?.proforma === REQUIRED_TYPE.MANDATORY)
  ) {
    generateCustomsData = true;
  }

  // for edit failed shipment there is case when generateCustomsData is invalid but defined
  // we must keep defined value to highlight error on UI
  if (
    dropdownVisible &&
    !dropdownDisabled &&
    has(shipment, "generateCustomsData")
  ) {
    generateCustomsData = shipment.generateCustomsData;
  }

  return {
    ...data,
    shipment: {
      ...shipment,
      generateCustomsData,
    },
  };
};

// @see: https://it.dpduk.live/it/diagram/diag_DWBhjD6GAqAAhaqs.html?id=1655210548970
export const setEditShipmentDate = data => {
  const { shipment, preferences, storageDate } = data;
  const enableWeekend = getValue(
    preferences,
    "shippingDefaults.enableWeekend",
    false
  );
  const defaultShipmentDate = getValue(
    preferences,
    "shippingDefaults.defaultShipmentDate"
  );

  const isShipmentDateBeforeToday = moment(
    shipment.shipmentDate,
    DATE_FORMAT.ISO_DATE_FORMAT
  ).isBefore(moment().startOf("day"));

  if (isShipmentDateBeforeToday) {
    const shipmentDate = getPreferredShipmentDate(
      storageDate,
      enableWeekend,
      defaultShipmentDate
    );

    return {
      ...data,
      shipment: {
        ...shipment,
        shipmentDate,
      },
    };
  } else {
    const minDate = getMinShipmentDate(enableWeekend);
    const currentShipmentDate = moment(
      shipment.shipmentDate,
      DATE_FORMAT.ISO_DATE_FORMAT
    );
    const shipmentDate = currentShipmentDate.isSameOrAfter(
      moment(minDate, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
    )
      ? currentShipmentDate.format(DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
      : minDate;

    return {
      ...data,
      shipment: {
        ...shipment,
        shipmentDate,
      },
    };
  }
};

export const getDefaultNumberOfParcelFromShipment = value =>
  value === 0 ? 1 : value;

export const setupDefaultValuesForInvalidOutboundConsignment = ({
  currencies,
  shipment,
  preferences,
  activeProfile,
  countriesKeyObject,
  network,
}) => {
  const deliveryCountry =
    countriesKeyObject[
      getValue(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE
      )
    ];
  const defaultCountryCode = getValue(deliveryCountry, "countryKey");
  const isAvailableCurrency = currencies.some(
    item =>
      item.currencyCode ===
      getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY)
  );
  const defaultLiability = LiabilityModels.getDefaultLiability(
    preferences,
    defaultCountryCode
  );
  const defaultLiabilityValue = roundToDecimal(
    LiabilityModels.getDefaultLiabilityValue(preferences, defaultCountryCode)
  );
  const outboundConsignment = shipment.outboundConsignment;

  // @see: https://geopost.jira.com/browse/CSHIP-6333
  // NOTE: must set correct totalWeight and numberOfParcels for proper fetching outboundServices
  set(
    outboundConsignment,
    Fields.TOTAL_WEIGHT,
    ServiceModels.roundTotalWeight(
      get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT),
      defaultCountryCode,
      getValue(network, Fields.NETWORK_CODE, ""),
      true
    )
  );
  set(
    outboundConsignment,
    Fields.NUMBER_OF_PARCELS,
    ServiceModels.roundNumberOfParcels(
      get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS)
    )
  );

  if (!isAvailableCurrency) {
    set(
      outboundConsignment,
      Fields.CURRENCY,
      CustomsModels.getDefaultCurrency(preferences, activeProfile)
    );
  }

  if (
    !isEmpty(shipment.invoice) &&
    !getValue(outboundConsignment, Fields.DELIVERY_DESCRIPTION)
  ) {
    set(
      outboundConsignment,
      Fields.DELIVERY_DESCRIPTION,
      getValue(
        preferences,
        "shippingDefaults.international.intContentDescription",
        ""
      ).toUpperCase()
    );
  }

  if (
    isNil(getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY))
  ) {
    set(outboundConsignment, Fields.LIABILITY, defaultLiability);
  }

  if (
    isNil(
      getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE)
    )
  ) {
    defaultLiabilityValue
      ? set(outboundConsignment, Fields.LIABILITY_VALUE, defaultLiabilityValue)
      : // should remove liabilityValue, because @@redux-form/REINITIALIZE action set undefined as empty string
        delete outboundConsignment.liabilityValue;
  }

  return {
    ...shipment,
    outboundConsignment,
  };
};

export const isReverseItProhibited = customerPrefs =>
  !get(customerPrefs, "allowAdhocReversIt");

export const isSwapItProhibited = customerPrefs =>
  !get(customerPrefs, "allowSwapit");

export const isReturnShipmentTypeProhibited = (customerPrefs, shipmentType) =>
  (isReverseItProhibited(customerPrefs) &&
    isReverseItShipmentType(shipmentType)) ||
  (isSwapItProhibited(customerPrefs) && isSwapItShipmentType(shipmentType));

export const setupBasicShipment = data => {
  const resultShipment = omit(
    pick(data.shipment, flattenEntityRoutes(ShipmentEntity)),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_DESCRIPTION]
  );

  set(resultShipment, ShipmentEntity.PROFILE_CODE, data.profile?.profileCode);
  set(
    resultShipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
    ServiceModels.roundTotalWeight(
      data.shipment.isVoided
        ? getValue(
            data.shipment,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DISPLAY_WEIGHT
          )
        : getValue(
            data.shipment,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT
          ),
      get(
        data.shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE
      ),
      getValue(data, "selectedOutboundNetwork.networkKey", "")
    )
  );
  // NOTE: blur this field convert value to string
  // as result serviceQuery and newServiceQuery will be different and launch redundant fetching services
  set(
    resultShipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
    get(
      data.shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
    )?.toString()
  );
  set(
    resultShipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
    data.selectedOutboundNetwork?.networkKey
  );
  set(
    resultShipment,
    ShipmentEntity.SHIPMENT_TYPE,
    isEmpty(data.shipment.inboundConsignment)
      ? SHIPMENT_TYPES.NO_TYPE.toString()
      : getValue(data.shipment, ShipmentEntity.SHIPMENT_TYPE).toString()
  );

  return { ...data, shipment: resultShipment };
};

export const setupGstFields = data => {
  if (
    !ServiceModels.isVisibleDestinationTaxIdRegNo(
      data.selectedOutboundNetwork,
      data.shipment.generateCustomsData
    )
  ) {
    delete data.shipment.outboundConsignment.destinationTaxId;
    delete data.shipment.outboundConsignment.gstVatPaid;
  }

  return { ...data, shipment: data.shipment };
};

export const setupReturnShipmentType = data => {
  if (
    isReturnShipmentTypeProhibited(
      data.customerPrefs,
      data.shipment.shipmentType
    )
  ) {
    return {
      ...data,
      shipment: {
        ...omit(data.shipment, [INBOUND_CONSIGNMENT]),
        [ShipmentEntity.SHIPMENT_TYPE]: SHIPMENT_TYPES.NO_TYPE.toString(),
      },
    };
  }

  return { ...data, shipment: data.shipment };
};

// TODO : check it for return sections
export const shouldDisablePrintButtons = (
  isValid,
  isDirty,
  isParcelsDataValid,
  submitErrors,
  activeField,
  isLoadingOutboundServices,
  isLoadingInboundServices
) =>
  isDirty
    ? !isValid ||
      !isParcelsDataValid ||
      !isEmpty(submitErrors) ||
      activeField ||
      isLoadingOutboundServices ||
      isLoadingInboundServices
    : !isDirty;

export const isCreateForm = formName => formName === CREATE_SHIPMENT_FORM;

export const isFailedForm = formName => formName === EDIT_FAILED_SHIPMENT_FORM;

export const isDisabledAdhocEditShipment = customerPrefs =>
  get(customerPrefs, "disableAdhocEditShipment", false);

export const isOldShipment = shipment =>
  moment().diff(moment(shipment.shipmentDate), "days") > 9;

// @see https://it.dpduk.live/it/diagram/diag_j9c6TD6GAqAAhde6.html?id=1639752991650
// @see https://it.dpduk.live/it/diagram/diag_c2oa1D6FYFxijXCV.html
export const isShipmentEditable = (shipment, customerPrefs) => {
  const shipmentType = get(shipment, "shipmentType");
  const allowAdhocReversIt = get(customerPrefs, "allowAdhocReversIt");
  const allowSwapit = get(customerPrefs, "allowSwapit");
  const allowEdit = get(shipment, "allowEdit");

  if (
    (isReverseItShipmentType(shipmentType) && !allowAdhocReversIt) ||
    (isSwapItShipmentType(shipmentType) && !allowSwapit)
  ) {
    return false;
  }

  return allowEdit;
};

// @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_bBoa1D6FYFxijXdh.html
export const getDeliveryInformationOptions = preferences =>
  reduce(
    getValue(preferences, "shippingDefaults"),
    (acc, value, key) => {
      if (S.DEFAULT_INFORMATIONS.includes(key) && value) {
        acc.push(value);
      }
      return acc;
    },
    []
  );

export const getInitialDestinationGstValues = (profile, preferences) => ({
  [Fields.DESTINATION_TAX_ID_REG_NO]:
    getValue(profile, "invDestinationTaxIdRegNo") ||
    getValue(preferences, "prefsInvoiceSettings.destinationTaxIdRegNo"),
  [Fields.GST_VAT_PAID]: null,
});

const prepareOutboundAddresses = ({
  shipment,
  profile,
  userCustomerAddress,
  customer,
  countries,
}) => {
  const outboundConsignment = shipment.outboundConsignment;
  const deliveryPath = `${DELIVERY_DETAILS}.${ADDRESS}`;
  const collectionDetails = ShipmentModels.getCollectionDetails(
    shipment,
    profile,
    userCustomerAddress,
    customer,
    countries
  );
  set(
    outboundConsignment,
    deliveryPath,
    omitNilValues(getValue(outboundConsignment, deliveryPath))
  );
  set(outboundConsignment, COLLECTION_DETAILS, collectionDetails);

  return {
    ...shipment,
    outboundConsignment,
  };
};

const removeEmptyInboundAddressValues = ({ shipment }) => {
  const inboundConsignment = cloneDeep(
    getValue(shipment, INBOUND_CONSIGNMENT, {})
  );
  const deliveryPath = `${DELIVERY_DETAILS}.${ADDRESS}`;
  const collectionPath = `${COLLECTION_DETAILS}.${ADDRESS}`;

  if (isEmpty(inboundConsignment)) {
    return shipment;
  }

  set(
    inboundConsignment,
    deliveryPath,
    omitNilValues(getValue(inboundConsignment, deliveryPath))
  );
  set(
    inboundConsignment,
    collectionPath,
    omitNilValues(getValue(inboundConsignment, collectionPath))
  );

  return {
    ...shipment,
    inboundConsignment,
  };
};

export const setupDefaultValuesForInvalidData = data => {
  const { shipment, network, countries } = data;
  const countriesKeyObject = mapArrayToObjectByKey(countries, "countryKey");
  let selectedCountry = data.deliveryCountry;
  let resultShipment = toUppercaseValues({
    ...shipment.shipmentDetails,
    stagingShipmentId: shipment.stagingShipmentId,
  });

  resultShipment = setNetworkShipmentType({
    shipment: resultShipment,
    network,
  });

  const isReturnShipmentType = isSwapItOrReverseItShipmentType(
    resultShipment.shipmentType
  );

  // NOTE: Defaulting incorrect delivery country for collect on delivery to preserve return details
  if (isReturnShipmentType && (!selectedCountry || isEmpty(selectedCountry))) {
    set(
      resultShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      S.GB
    );
    set(
      resultShipment,
      "inboundConsignment.collectionDetails.address.countryCode",
      S.GB
    );
    selectedCountry = { countryKey: S.GB };
  }

  resultShipment = prepareOutboundAddresses({
    ...data,
    shipment: resultShipment,
  });
  resultShipment = removeEmptyInboundAddressValues({
    shipment: resultShipment,
  });
  resultShipment = setupDefaultValuesForInvalidOutboundConsignment({
    ...data,
    countriesKeyObject,
    shipment: resultShipment,
  });
  resultShipment = InvoiceModels.setupDefaultValuesForInvalidInvoice({
    ...data,
    shipment: resultShipment,
  });

  // NOTE: do it after setupDefaultValuesForInvalidOutboundConsignment
  if (isSwapItShipmentType(resultShipment.shipmentType)) {
    // NOTE: inbound totalWeight always must be equal outbound totalWeight
    // @see:https://geopost.jira.com/browse/CSHIP-6560 user can pass to the csv any value
    set(
      resultShipment,
      ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      getValue(resultShipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT)
    );
  }

  return { shipment: resultShipment, selectedCountry };
};

export const getAddressBookRequiredFields = (
  selectedCountry,
  shipmentRequiredFields
) => {
  const isInternationalNonEUCountry =
    !isCountryDomestic(selectedCountry.countryKey) &&
    !get(selectedCountry, "euCountry", false);

  return {
    ...shipmentRequiredFields,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .CONTACT_NAME]: isInternationalNonEUCountry,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .TELEPHONE]: isInternationalNonEUCountry,
  };
};

export const getAddressBookErrors = (
  values,
  selectedService,
  selectedCountry,
  user,
  customer,
  searchAddressDetailsValues,
  countries,
  requiredFields
) => {
  const props = {
    selectedService,
    selectedCountry,
    user,
    customer,
    searchAddressDetailsValues,
    countries,
    requiredFields,
    values,
  };

  return createValidator(addressBookSchema, [
    () =>
      postcodeValidation(
        props,
        selectedCountry,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
      ),
  ])(values, props);
};

export const getShipmentRequiredFields = (
  formValues,
  selectedOutboundService,
  selectedInboundService,
  commonRequiredFields,
  selectedCountry,
  customsFieldsFlags,
  isVisibleDestinationTaxIdRegNo,
  invoiceRequiredFields,
  selectedReturnCountry,
  preferences,
  profile
) => {
  const liability = get(
    formValues,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
    false
  );
  const { networkCode } = getPackageDetails(formValues);
  const notifyRequired = ServiceModels.isNotifyRequired(
    selectedOutboundService
  );
  const { networkCode: returnNetworkCode } =
    getReturnPackageDetails(formValues);
  const returnNotifyRequired = ServiceModels.isNotifyRequired(
    selectedInboundService
  );
  const countryCode = get(selectedCountry, "countryKey", "");
  const taxRequired = get(selectedOutboundService, "taxRequired", "");
  const isReturnPostcodeRequired = get(
    selectedReturnCountry,
    "isPostcodeRequired"
  );
  const { dropdownVisible } = getGenerateCustomsDataSettings(
    selectedOutboundService,
    preferences,
    profile
  );
  const isPostcodeRequired = getValue(selectedCountry, "isPostcodeRequired");

  return {
    ...invoiceRequiredFields,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .CONTACT_NAME]: networkCode && notifyRequired,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .TELEPHONE]: (countryCode && countryCode !== S.GB) || notifyRequired,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO]:
      isVisibleDestinationTaxIdRegNo && taxRequired === REQUIRED_TYPE.MANDATORY,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID]:
      isVisibleDestinationTaxIdRegNo &&
      !!getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
      ),
    [ShipmentEntity.SHIPMENT_DATE]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]: true,
    [ShipmentEntity.GENERATE_CUSTOMS_DATA]: dropdownVisible,
    ...commonRequiredFields,
    // NOTE: must overwrite it here because commonRequiredFields uses for ability to update addressbook
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
      countryCode && isPostcodeRequired,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
      .COUNTRY_CODE]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
      .COUNTRY_CODE]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
      isReturnPostcodeRequired,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .CONTACT_NAME]: !isEmpty(returnNetworkCode) && returnNotifyRequired,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .TELEPHONE]: countryCode && countryCode !== S.GB,
    [ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY]:
      CustomsModels.isCustomsFieldsVisible(
        selectedOutboundService,
        selectedCountry,
        formValues
      ),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE]:
      customsFieldsFlags.customsValue,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]:
      customsFieldsFlags.deliveryDescription,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE]:
      liability && !isCountryDomestic(countryCode),
    [ShipmentEntity.INVOICE.EXPORT_REASON]:
      customsFieldsFlags[Fields.EXPORT_REASON],
    [ShipmentEntity.INVOICE.SHIPPING_COST]:
      customsFieldsFlags[Fields.SHIPPING_COST],
  };
};

export const getShipmentDetailsReview = (
  formValues,
  selectedService,
  allowedFields,
  products,
  shippingFreightCost,
  parcelsTotalValue,
  touchedErrorFields
) =>
  CardModels.mapShipmentDetails(
    getPackageDetails(formValues),
    selectedService,
    get(formValues, Fields.SHIPMENT_TYPE),
    allowedFields,
    products,
    shippingFreightCost,
    parcelsTotalValue,
    touchedErrorFields
  );

export const getCommonAllowedFields = (
  { deliveryDescriptionHidden },
  requiredFields,
  isAllowedDestinationTaxIdRegNo,
  formValues,
  isCustomsFieldsAllowed,
  securitySettings,
  selectedService,
  shipmentTypes,
  preferences,
  profile
) => {
  const { dropdownVisible } = getGenerateCustomsDataSettings(
    selectedService,
    preferences,
    profile
  );
  const isCustomsDataCardAllowed =
    getGenerateCustomsDataMessage(
      selectedService,
      formValues?.[ShipmentEntity.GENERATE_CUSTOMS_DATA],
      preferences
    ) ||
    getDirectShipmentMessage(
      selectedService,
      get(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      )
    );

  return {
    searchAddress: {
      [INBOUND_CONSIGNMENT_UPPERCASE]: true,
    },
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]:
      !deliveryDescriptionHidden,
    deliveryContactCard:
      requiredFields[
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
          .NOTIFICATION_DETAILS.MOBILE
      ] ||
      requiredFields[
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
          .NOTIFICATION_DETAILS.EMAIL
      ],
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO]:
      isAllowedDestinationTaxIdRegNo,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID]:
      isAllowedDestinationTaxIdRegNo &&
      !!getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
      ),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE]:
      LiabilityModels.isLiabilityValueVisible(formValues),
    domesticService: isCountryDomestic(
      get(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      )
    ),
    customs: isCustomsFieldsAllowed,
    extendedLiability: LiabilityModels.getIsExtendedLiabilityFlagAllowed(
      securitySettings,
      selectedService
    ),
    [ShipmentEntity.SHIPMENT_TYPE]:
      get(selectedService, "collectOnDelivery", false) &&
      shipmentTypes.length > 0,
    [ShipmentEntity.GENERATE_CUSTOMS_DATA]: dropdownVisible,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]:
      isSwapItShipmentType(formValues?.[ShipmentEntity.SHIPMENT_TYPE]),
    returnCards: isSwapItOrReverseItShipmentType(
      formValues?.[ShipmentEntity.SHIPMENT_TYPE]
    ),
    invoiceCards: formValues?.[ShipmentEntity.GENERATE_CUSTOMS_DATA],
    customsDataCard: isCustomsDataCardAllowed,
    productDetailsData:
      isProductsRequired(selectedService) ||
      formValues?.[ShipmentEntity.GENERATE_CUSTOMS_DATA],
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]: true,
    editExporter: true,
    editImporter: true,
  };
};

// @see: https://it.dpduk.live/it/diagram/diag_dIlhMP6GAqCIalTS.html
export const setUniqueShippingRef1 = data => {
  const { shipment, preferences, shippingSettings, uniqueSenderRef1 } = data;

  if (
    getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1, "")
  ) {
    return data;
  }

  const outboundConsignment = shipment.outboundConsignment;
  const ref1 = ShipmentModels.getDefaultUniqueShippingRef1(
    getValue(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
    ),
    uniqueSenderRef1,
    {
      preferences,
      shippingSettings,
    }
  );
  set(outboundConsignment, Fields.SHIPPING_REF_1, ref1);

  return {
    ...data,
    shipment: {
      ...shipment,
      outboundConsignment,
    },
  };
};

export const shouldGenerateUniqueSenderRef1 = (formName, allocateSenders) =>
  (isCreateForm(formName) || isFailedForm(formName)) && allocateSenders;

import { EventLogger } from "@dpdgroupuk/mydpd-app";
import { PRINTER_TYPE } from "@dpdgroupuk/mydpd-enums";

import { importsApi } from "~/apis";
import { NOTICE_LEVEL } from "~/constants/importActivity";
import {
  NO_PERMISSIONS_TO_CREATE_SHIPMENT,
  NO_PRIVILEGES_TO_ACCESS_SYSTEM,
} from "~/constants/strings";
import * as Helper from "~/features/Importer/Helper";
import PrintController from "~/features/Importer/PrintController";
import { ImporterSelectors } from "~/features/Importer/redux";
import { ignoreCatch } from "~/utils/async";
import { getErrorMessage, getHttpErrorMessage } from "~/utils/error";

import FileReader from "./FileReader";

class BatchImport extends PrintController {
  // auto import with force = false
  async runImportJob(params, force = false) {
    const { shipmentTemplate } = params;
    const importDir = shipmentTemplate.workingDir;
    const importFileExtension = shipmentTemplate.fileExtension;
    const { appState } = await this.manager.checkStatus();

    if (!appState.import) {
      try {
        this.emit("importing", true);
        const files = await this.localApis.fs
          .list(importDir, {
            extension: importFileExtension,
          })
          .catch(err => {
            this.logger.error(
              err.statusCode === 404
                ? `Directory ${importDir} not found`
                : getErrorMessage(err),
              err
            );

            throw err;
          });
        if (!files.length) {
          force && this.logger.info("Nothing to process");
        }
        for (const file of files) {
          try {
            await this.processFileImport(file, params);
          } catch (e) {
          } finally {
            await this.localApis.fs.remove(file);
          }
        }
      } finally {
        this.emit("importing", false);
      }
    }
  }

  // import on demand call
  async runImportJobForce(templateId) {
    const params = ImporterSelectors.getImportParams(
      this.store.getState(),
      templateId
    );
    return this.runImportJob(params, true);
  }

  async processFileImport(filePath, params) {
    // @see:https://it.dpduk.live/it/diagram/diag_MjCcx76GAqAAhdFr.html?id=1656607918501
    if (!params.appRoles.includes("shipease")) {
      const err = new Error(NO_PRIVILEGES_TO_ACCESS_SYSTEM);
      this.logger.error(err.message);
      throw err;
    }
    if (params.securitySettings.disableAdhocEditShipment) {
      const err = new Error(NO_PERMISSIONS_TO_CREATE_SHIPMENT);
      this.logger.error(err.message);
      throw err;
    }
    const {
      shipmentTemplate,
      shipmentReceiptTemplate,
      prefsConfigSettings,
      shippingSettings,
    } = params;
    const importPrint = shipmentTemplate.autoPrint;
    const receiptEnabled = shipmentReceiptTemplate
      ? shipmentReceiptTemplate.createReceipt
      : null;
    const oneReceiptPerShipment = shipmentReceiptTemplate
      ? shipmentReceiptTemplate.oneReceiptPerShipment
      : null;
    const printerType = prefsConfigSettings.printToThermal
      ? +prefsConfigSettings.thermalPrinterType
      : PRINTER_TYPE.LASER;
    const autoWorkStationField = shipmentTemplate.includeFields.filter(
      column => column.templateFieldId === "autoworkstation_id"
    );

    const file = new FileReader(filePath);

    await file.validate().catch(err => {
      this.logger.error(err.message);
      throw err;
    });

    // once BE done call instead this.importFileByChunks(filePath, params);
    const jobId = await this.importFile(file, params);

    const importResult = await this.processJobResult(jobId, {
      fileName: file.name,
      ...params,
    });
    const { totalLabels, success, failure, entries } = importResult;
    const shipmentIds = entries
      .filter(entry => !!entry.shipmentId)
      .map(entry => entry.shipmentId);

    // Process receipt
    const processReceipt =
      receiptEnabled &&
      !oneReceiptPerShipment &&
      autoWorkStationField.length === 0; // if workstation configured postpone it to workstation job

    if (processReceipt) {
      await ignoreCatch(
        this.receipt.generateReceipt(jobId, undefined, {
          shipmentReceiptTemplate,
        })
      );
    }

    if (failure && !shippingSettings.onScreenImportFix) {
      // process bad file
      await ignoreCatch(this.processBadFile(jobId, importResult, params));
    }

    if (autoWorkStationField.length > 0) {
      await importsApi.completeWorkstationJob(jobId).catch(err => {
        this.logger.error("Failed to complete print job", err);

        throw err;
      });
    } else {
      if (importPrint) {
        if (
          printerType !== PRINTER_TYPE.CITIZEN &&
          printerType !== PRINTER_TYPE.ELTRON &&
          printerType !== PRINTER_TYPE.ZEBRA
        ) {
          return this.logger.error(
            "Automated printing support is only available to Citizen or Zebra thermal printers",
            {
              printerType,
            }
          );
        }

        if (!entries.length) {
          return this.logger.info("Nothing to print");
        }

        if (success > 0 || failure > 0) {
          let printStrings = await this.getImportLabels(
            jobId,
            undefined,
            params
          ).catch(err => {
            this.logger.error("Failed to print labels", err);

            throw err;
          });

          if (!printStrings.length) {
            this.logger.info("Nothing to print");

            return;
          }

          if (entries.length === failure) {
            printStrings = [printStrings[0]];
          }

          if (receiptEnabled && oneReceiptPerShipment && !!shipmentIds.length) {
            await ignoreCatch(
              this.receipt.generateReceiptsPerShipments(shipmentIds, params)
            );
          }

          await ignoreCatch(this.printImportLabels(printStrings));
        }

        return;
      } // Display that there are records to be printed

      if (totalLabels > 0) {
        this.logger.info(`${totalLabels} label(s) waiting to be printed`, {
          totalLabels,
        });
      } else {
        this.logger.info("Nothing to print", {
          totalLabels,
        });
      }
    }
  }

  showProgress(meta) {
    this.logger.emit(
      "progress",
      EventLogger.logEntry(
        NOTICE_LEVEL,
        `Processing file ${meta.processed}/${meta.total}`,
        meta.id
      )
    );
  }

  async createImportJob(templateId, detail) {
    const { data } = await importsApi.createImportJobId(templateId, {
      headers: detail.headers,
      footers: detail.footers,
      fileName: detail.name,
    });
    const jobId = data.jobId;
    this.logger.info(
      `Successfully uploaded file ${detail.name} with job number ${jobId}`,
      {
        path: detail.path,
        jobId,
      }
    );
    return jobId;
  }

  importRows(jobId, detail, onLinesChunkSuccess) {
    // do chunking for parallel requests
    return Helper.groupImportLines(detail.lines).reduce(
      async (acc, rows) => {
        const result = await acc;
        result.end = result.start + (rows.length - 1);

        const linePos = result.start;

        // increment for next chunk processing
        result.start = result.end + 1;

        await importsApi.runImportJobId(jobId, {
          lines: rows,
          linePos,
        });

        onLinesChunkSuccess && onLinesChunkSuccess(rows);

        return result;
      },
      Promise.resolve({
        start: detail.startAt,
        end: detail.startAt,
      })
    );
  }

  async importFile(file, params) {
    const result = {
      processed: 0,
      total: 0,
      id: Math.random().toString(16).slice(2),
      jobId: null,
    };
    let processImportJobPromise = Promise.resolve();

    this.logger.info(`Processing file ${file.name}`, {
      path: file.path,
    });

    file.addListener("metadata", async ({ detail }) => {
      processImportJobPromise = this.createImportJob(
        params.shipmentTemplate.templateId,
        detail
      ).then(jobId => {
        result.jobId = jobId;
        return jobId;
      });
    });

    file.addListener("data", ({ detail }) => {
      result.total = detail.total;
      this.showProgress(result);
      processImportJobPromise = processImportJobPromise.then(() =>
        this.importRows(result.jobId, detail, rows => {
          result.processed += rows.length;
          this.showProgress(result);
        })
      );
    });

    await file.read(params).catch(err => {
      this.logger.error(err.message);
      throw err;
    });

    await processImportJobPromise.catch(err => {
      // Log error
      this.logger.error(
        `There were errors importing file ${file.name}: ${
          getHttpErrorMessage(err) || "unknown error"
        }`,
        err
      );
      throw err;
    });

    return result.jobId;
  }

  async processJobResult(jobId, params) {
    const importResult = await importsApi
      .getImportResultByJobId(jobId)
      .catch(err => {
        this.logger.error(
          `Unable to fetch import activity for ${
            params.fileName
          }: ${getHttpErrorMessage(err)}`,
          err
        );
        throw err;
      });

    const { filename, success, entries } = importResult;

    this.logger.success(
      `Finished processing file ${filename} (${success}/${entries.length})`,
      importResult
    );

    const { warnings, errors } = Helper.processImportResults(
      jobId,
      importResult,
      params
    );
    warnings.forEach(warning => {
      this.logger.warn(warning);
    });

    errors.slice(-250).forEach(error => {
      this.logger.error(error);
    });

    return importResult;
  }

  async processBadFile(jobId, importResult, { shipmentTemplate }) {
    const importDir = shipmentTemplate.workingDir;
    const { fileName, badData } = await importsApi
      .getFailedImportResultByJobId(jobId)
      .catch(err => {
        this.logger.error("Unable to fetch bad data", err);
        throw err;
      });
    if (badData.length) {
      this.logger.info(
        `Generating error file ${fileName} (${importResult.failure}/${importResult.entries.length})`
      );
      const contents = badData
        .join("\r\n")
        .replace(/\\t/g, String.fromCharCode(9));
      await this.localApis.fs
        .write(importDir, fileName, contents)
        .catch(err => {
          this.logger.error(
            `Cannot write bad file, check that directory "${importDir}" exists and is writeable`,
            err
          );
        });
    }
  }
}

export default BatchImport;

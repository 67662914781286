import { useMemo } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose, lifecycle } from "recompose";
import { Field, reduxForm } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import {
  SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";
import { isWeekend } from "~/utils/date";

import Modal from "../ShipmentReviewModal";
import styles from "./PrintUnprintedModal.module.scss";

const PrintUnprintedModal = ({
  onSubmit,
  enableWeekend,
  handleSubmit,
  open,
  onCancel,
}) => {
  const { minDate, maxDate } = useMemo(
    () => ShipmentModels.getAvailableDateRange(enableWeekend),
    [enableWeekend]
  );

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={S.PRINT_UNPRINTED}
      className={styles.printUnprintedModal}
      saveButton={{ visible: true, text: S.PRINT }}
      onSave={handleSubmit(onSubmit)}
    >
      <Container className="p-0">
        <Row>
          <Col>
            <Field
              readOnly
              component={FormControl.DatePicker}
              label={S.SHIPMENT_DATE}
              name={ShipmentEntity.SHIPMENT_DATE}
              helperText={S.FORWARD_SHIPMENT_DATE}
              dateFormat={DEFAULT_DATE_FORMAT}
              minDate={minDate}
              maxDate={maxDate}
              filterDate={date => enableWeekend || !isWeekend(date)}
              required
            />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

PrintUnprintedModal.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  enableWeekend: PropTypes.bool,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default compose(
  reduxForm({
    form: SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM,
    initialValues: {
      [ShipmentEntity.SHIPMENT_DATE]: moment().format(DEFAULT_DATE_FORMAT),
    },
    destroyOnUnmount: false,
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (!this.props.open && prevProps.open) {
        this.props.reset();
      }
    },
  })
)(PrintUnprintedModal);

/* eslint-disable react/prop-types */
import { useMemo, useState } from "react";

import { get } from "lodash";
import { every } from "lodash/collection";
import PropTypes from "prop-types";
import { useColumnOrder } from "react-table/src/plugin-hooks/useColumnOrder";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import { useResizeColumns } from "react-table/src/plugin-hooks/useResizeColumns";
import { useRowSelect } from "react-table/src/plugin-hooks/useRowSelect";

import { useUserHeaderStatePersist } from "@dpdgroupuk/mydpd-app";
import { Button, DndTable } from "@dpdgroupuk/mydpd-ui";

import * as S from "~/constants/strings";

import styles from "./PackageDetailsTable.module.scss";
import TableScrollResizer from "./TableScrollResizer";

const getParcelsTableDataColumns = ({
  onClickReprintByParcelNumber,
  allowPrintShipment,
}) => [
  {
    Header: S.PARCEL,
    accessor: "parcelNumber",
    minWidth: 150,
  },
  {
    Header: S.REPRINT,
    accessor: "reprint",
    minWidth: 115,
    width: 115,
    Cell: ({ row: { original } }) => (
      <Button
        disabled={!allowPrintShipment || original.isVoided}
        variant="primary"
        className={styles.roundedButton}
        onClick={e => {
          e.stopPropagation();
          onClickReprintByParcelNumber(get(original, "parcelNumber"));
        }}
      >
        {S.REPRINT}
      </Button>
    ),
  },
  {
    Header: S.STATUS,
    accessor: "isVoided",
    minWidth: 110,
    width: 110,
    Cell: ({ row: { original } }) =>
      S.BOOLEAN_TO_VOIDED_STATUS[get(original, "isVoided", false)],
  },
];

const PackageDetailsTable = ({
  data,
  onClickVoidParcels,
  onClickUnvoidParcels,
  onClickReprintByParcelNumber,
  allowPrintShipment,
  showButtonsToolbar,
  actionPermissions,
}) => {
  const [selectedParcels, setSelectedParcels] = useState([]);
  const memoizedData = useMemo(() => data, [data]);

  const columns = useMemo(
    () =>
      getParcelsTableDataColumns({
        onClickReprintByParcelNumber,
        allowPrintShipment,
      }),
    [allowPrintShipment, onClickReprintByParcelNumber]
  );
  const { disableVoidButton, disableUnvoidButton } = useMemo(() => {
    const disabled =
      !selectedParcels.length ||
      selectedParcels.some(parcel => parcel.products.length) ||
      (!actionPermissions.allowVoid && !actionPermissions.allowUnvoid);
    return {
      disableVoidButton: disabled || every(selectedParcels, "isVoided"),
      disableUnvoidButton:
        disabled || every(selectedParcels, ["isVoided", false]),
    };
  }, [
    selectedParcels,
    actionPermissions.allowVoid,
    actionPermissions.allowUnvoid,
  ]);
  const { allowSelectRows, tableHooks, storageKey } = useMemo(() => {
    const allowSelectRows =
      actionPermissions.allowVoid || actionPermissions.allowUnvoid;
    const tableHooks = [
      useFlexLayout,
      useResizeColumns,
      useColumnOrder,
      useUserHeaderStatePersist,
    ];
    allowSelectRows && tableHooks.push(useRowSelect);
    // Use separate cookies for Package Details tables at Outbound and Return tabs
    const storageKey =
      "packageDetails" + (allowSelectRows ? "Outbound" : "Return");
    return {
      allowSelectRows,
      tableHooks,
      storageKey,
    };
  }, [actionPermissions.allowVoid, actionPermissions.allowUnvoid]);

  return (
    <TableScrollResizer memoizedData={memoizedData}>
      <DndTable
        key={allowPrintShipment}
        data={memoizedData}
        columns={columns}
        classes={{
          container: styles.tableContainer,
          table: styles.table,
        }}
        tableHooks={tableHooks}
        initialState={{
          storageKey,
        }}
        getSelectedRows={allowSelectRows ? setSelectedParcels : null}
        selectable={allowSelectRows}
      >
        {showButtonsToolbar && (
          <Button.Toolbar className="p-2">
            <Button
              disabled={disableVoidButton}
              onClick={() => onClickVoidParcels(selectedParcels)}
              variant="dark"
            >
              {S.VOID_SELECTED}
            </Button>
            <Button
              disabled={disableUnvoidButton}
              onClick={() => onClickUnvoidParcels(selectedParcels)}
              variant="dark"
            >
              {S.UNVOID_SELECTED}
            </Button>
          </Button.Toolbar>
        )}
      </DndTable>
    </TableScrollResizer>
  );
};

PackageDetailsTable.defaultProps = {
  actionPermissions: {},
};

PackageDetailsTable.propTypes = {
  data: PropTypes.array,
  onClickVoidParcels: PropTypes.func,
  onClickUnvoidParcels: PropTypes.func,
  onClickReprintByParcelNumber: PropTypes.func,
  allowPrintShipment: PropTypes.bool,
  showButtonsToolbar: PropTypes.bool,
  actionPermissions: PropTypes.object,
};

export default PackageDetailsTable;

import { createSelector } from "reselect";

import { PRINTER_TYPE } from "@dpdgroupuk/mydpd-enums";

export const getData = state => state.app.ums;

export const getPreferences = createSelector(
  getData,
  ({ preferences }) => preferences
);
export const getConfigSettings = createSelector(
  getPreferences,
  preferences => preferences.prefsConfigSettings
);

export const getPrinterType = createSelector(
  getConfigSettings,
  ({ thermalPrinterType, printToThermal }) =>
    printToThermal ? +thermalPrinterType : PRINTER_TYPE.LASER
);

export const getPrinterName = createSelector(
  getConfigSettings,
  ({ thermalPrinterName, printToThermal }) =>
    (printToThermal && thermalPrinterName) || ""
);

export const getCustomer = createSelector(getData, ({ customer }) => customer);
export const getCustomerPrefs = createSelector(
  getData,
  ({ customerPrefs }) => customerPrefs
);
export const getCustomerServices = createSelector(
  getData,
  ({ customerServices }) => customerServices
);
export const getAdvancedCustomerConfiguration = createSelector(
  getData,
  ({ advancedConfiguration }) => advancedConfiguration
);
export const getShippingSettings = createSelector(
  getData,
  ({ shippingSettings }) => shippingSettings
);
export const getCustomerAddress = createSelector(
  getData,
  ({ customerAddress }) => customerAddress
);
export const getSecuritySettings = createSelector(
  getData,
  ({ securitySettings }) => securitySettings
);

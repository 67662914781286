import { findIndex } from "lodash";

import { persistUserReducer } from "@dpdgroupuk/mydpd-app";

import { NOTICE_LEVEL } from "~/constants/importActivity";

import { ActionTypes, NAMESPACE } from "./actions";

const MAX_NUMBER_OF_MESSAGES = 250;

const takeMessages = messages =>
  messages
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, MAX_NUMBER_OF_MESSAGES);

export const initialState = {
  isRunning: false,
  templateId: null,
  importMessages: [],
  runningState: {
    importing: false,
    printing: false,
  },
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_TEMPLATE_ID: {
      return {
        ...state,
        templateId: action.payload,
      };
    }

    case ActionTypes.SET_RUNNING_STATE: {
      return {
        ...state,
        runningState: {
          ...state.runningState,
          [action.payload.flag]: action.payload.value,
        },
      };
    }

    case ActionTypes.ADD_IMPORT_MESSAGE: {
      const importMessages = takeMessages([
        action.payload,
        ...state.importMessages,
      ]);

      return {
        ...state,
        importMessages,
      };
    }

    case ActionTypes.ADD_IMPORT_PROGRESS: {
      const messages = [...state.importMessages];
      const index = findIndex(messages, {
        level: NOTICE_LEVEL,
        payload: action.payload.payload,
      });

      index > -1
        ? // add new progress message at top
          messages.splice(index, 1, action.payload)
        : // update progress message
          messages.splice(0, 0, action.payload);

      return {
        ...state,
        importMessages: takeMessages(messages),
      };
    }

    case ActionTypes.CLEAR_IMPORT_MESSAGES: {
      return {
        ...state,
        importMessages: initialState.importMessages,
      };
    }

    default:
      return state;
  }
};

export default persistUserReducer(
  {
    key: NAMESPACE,
    whitelist: ["importMessages"],
  },
  Reducer
);

import { AddressBookActionTypes } from "./actions";

export const initialState = {
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AddressBookActionTypes.FETCH.SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case AddressBookActionTypes.CLEAR_ADDRESS_BOOK: {
      return initialState;
    }
    default:
      return state;
  }
};

/* eslint-disable react/prop-types */
import moment from "moment";

import { PROJECT_NAME } from "@dpdgroupuk/mydpd-app";

import { Lock, Pen, Photo } from "~/components/Icon";
import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import { ShipmentEntity } from "~/constants/forms";
import {
  BOOLEAN_TO_STRING,
  CLICK_TO_TRACK,
  NOT_PRINTED,
} from "~/constants/strings";
import { ServiceModels } from "~/models";
import { isShipmentEditable } from "~/models/shipment/shipment";
import { roundToDecimal } from "~/utils/number";
import { getValue } from "~/utils/object";

const getDeliveryReference = ({ outboundConsignment }, props) =>
  `${props.urlResolver.getUrl(
    PROJECT_NAME.DELIVERIES
  )}/search?parcelReference=${outboundConsignment.consignmentNumber}`;

export const MAP_COLUMN_NAMES_FOR_EXPORT = {
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]: "outbound.customerRef1",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2]: "outbound.customerRef2",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3]: "outbound.customerRef3",
  "outboundConsignment.shippingRef4": "outbound.customerRef4",
  "outboundConsignment.shippingRef6": "outbound.customerRef6",
  [ShipmentEntity.SHIPMENT_DATE]: "shipmentDate",
  "outboundConsignment.consignmentNumber": "outbound.consignmentNumber",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE]:
    "outbound.deliveryDetails.address.countryCode",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
    "outbound.deliveryDetails.address.postcode",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_DESCRIPTION]:
    "outbound.networkDescription",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]:
    "outbound.numberOfParcels",
  shipmentStatus: "shipmentStatus", // NOTE: Is not supported by the BE yet
  isVoided: "isVoided",
  isPrinted: "isPrinted",
  isManifested: "isManifested",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.ORGANISATION]:
    "outbound.deliveryDetails.address.organisation",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET]:
    "outbound.deliveryDetails.address.street",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY]:
    "outbound.deliveryDetails.address.locality",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN]:
    "outbound.deliveryDetails.address.town",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY]:
    "outbound.deliveryDetails.address.county",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
    .CONTACT_NAME]: "outbound.deliveryDetails.contactDetails.contactName",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
    .TELEPHONE]: "outbound.deliveryDetails.contactDetails.telephone",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION]:
    "outbound.deliveryInstructions",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
    .EMAIL]: "outbound.deliveryDetails.notificationDetails.email",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
    .MOBILE]: "outbound.deliveryDetails.notificationDetails.mobile",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE]: "outbound.customsValue",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]:
    "outbound.deliveryDescription",
  profileCode: "profileCode",
  profileName: "profileName",
  shippingAccount: "shippingAccount",
  userName: "userName",
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]: "outbound.totalWeight",
  holdStatus: "holdStatus",
};

export const ALWAYS_VISIBLE_SHIPMENT_COLUMNS = ["selection", "view", "edit"];

export const getShipmentReviewColumns = props => [
  {
    Header: "Reference 1",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
    minWidth: 100,
  },
  {
    Header: "Reference 2",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2,
    minWidth: 100,
  },
  {
    Header: "Reference 3",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3,
    minWidth: 100,
  },
  {
    Header: "Reference 4",
    accessor: "outboundConsignment.shippingRef4",
    minWidth: 100,
  },
  {
    Header: "Reference 6",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_6,
    minWidth: 100,
  },
  {
    Header: "Shipment Date",
    accessor: ShipmentEntity.SHIPMENT_DATE,
    Cell: ({ row: { original } }) =>
      moment(getValue(original, ShipmentEntity.SHIPMENT_DATE, "")).format(
        DEFAULT_DATE_FORMAT
      ),
    minWidth: 115,
    width: 115,
  },
  {
    Header: "Consignment",
    accessor: "outboundConsignment.consignmentNumber",
    minWidth: 110,
    width: 110,
  },
  {
    Header: "Country",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
    minWidth: 80,
    width: 80,
  },
  {
    Header: "Postcode",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
    minWidth: 90,
    width: 90,
  },
  {
    Header: "Product/Service",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_DESCRIPTION,
    minWidth: 140,
    width: 170,
  },
  {
    Header: "Packages",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
    minWidth: 85,
    width: 85,
  },
  {
    Header: "Status",
    accessor: "shipmentStatus",
    Cell: ({ row: { original } }) =>
      original.isPrinted ? (
        <a
          href={getDeliveryReference(original, props)}
          target="_blank"
          rel="noreferrer"
        >
          {CLICK_TO_TRACK}
        </a>
      ) : (
        NOT_PRINTED
      ),
    minWidth: 120,
  },
  {
    Header: "Voided",
    accessor: "isVoided",
    Cell: ({ row: { original } }) =>
      BOOLEAN_TO_STRING[getValue(original, "isVoided", false)],
    minWidth: 75,
    width: 75,
  },
  {
    Header: "Printed",
    accessor: "isPrinted",
    Cell: ({ row: { original } }) =>
      BOOLEAN_TO_STRING[getValue(original, "isPrinted", false)],
    minWidth: 75,
    width: 75,
  },
  {
    Header: "Manifested",
    accessor: "isManifested",
    Cell: ({ row: { original } }) =>
      BOOLEAN_TO_STRING[getValue(original, "isManifested", false)],
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Organisation/Name",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.ORGANISATION,
    minWidth: 150,
  },
  {
    Header: "Address Line 1",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET,
    minWidth: 100,
  },
  {
    Header: "Address Line 2",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY,
    minWidth: 100,
  },
  {
    Header: "Address Line 3",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN,
    minWidth: 100,
  },
  {
    Header: "Address Line 4",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY,
    minWidth: 100,
  },
  {
    Header: "Contact Name",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .CONTACT_NAME,
    minWidth: 100,
  },
  {
    Header: "Contact Number",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .TELEPHONE,
    minWidth: 120,
  },
  {
    Header: "Additional Information",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION,
    minWidth: 160,
  },
  {
    Header: "Notification Email",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .EMAIL,
    minWidth: 140,
  },
  {
    Header: "Notification Text",
    accessor:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .MOBILE,
    minWidth: 120,
  },
  {
    Header: "Customs Value",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE,
    minWidth: 120,
    Cell: ({ row: { original } }) =>
      roundToDecimal(
        getValue(original, ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE)
      ),
  },
  {
    Header: "Contents",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
    minWidth: 120,
  },
  {
    Header: "Profile Code",
    accessor: "profileCode",
    minWidth: 100,
  },
  {
    Header: "Profile Name",
    accessor: "profileName",
    minWidth: 100,
  },
  {
    Header: "Shipping Account",
    accessor: "shippingAccount",
    minWidth: 140,
  },
  {
    Header: "User Name",
    accessor: "userName",
    minWidth: 100,
  },
  {
    Header: "Total Weight",
    accessor: ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
    minWidth: 100,
    Cell: ({ row: { original } }) =>
      ServiceModels.roundTotalWeight(
        getValue(original, ShipmentEntity.OUTBOUND_CONSIGNMENT.DISPLAY_WEIGHT),
        getValue(
          original,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE
        ),
        getValue(original, ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE, "")
      ),
  },
  {
    Header: "Held",
    accessor: "holdStatus",
    minWidth: 75,
    width: 75,
  },
];

export const getShipmentActionColumns = (
  onViewShipmentClick,
  onEditShipmentClick,
  customerPrefs
) => [
  {
    Header: "View",
    accessor: "view",
    minWidth: 75,
    width: 75,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row: { original } }) => (
      <button onClick={onViewShipmentClick(getValue(original, "shipmentId"))}>
        <Photo />
      </button>
    ),
  },
  {
    Header: "Edit",
    accessor: "edit",
    minWidth: 90,
    width: 90,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row: { original } }) =>
      isShipmentEditable(original, customerPrefs) ? (
        <button onClick={onEditShipmentClick(getValue(original, "shipmentId"))}>
          <Pen />
        </button>
      ) : (
        <Lock />
      ),
  },
];

import React from "react";

import classNames from "classnames";
import get from "lodash/get";
import set from "lodash/set";
import moment from "moment";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "recompose";
import { propTypes, reduxForm } from "redux-form";

import {
  ROUTE,
  withAppContext,
  withAppLoader,
  withAppUserPreferences,
  withLocalServiceState,
  withNotifier,
} from "@dpdgroupuk/mydpd-app";
import { isUnauthorizedUserError } from "@dpdgroupuk/mydpd-app/lib/features/Notifier/utils";
import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";
import {
  Banner,
  Button,
  Card,
  getFileAttributes,
  Legend,
  Main,
  withOverlay,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import { localApi } from "~/apis";
import {
  DAY_DEFAULT_DATE_FORMAT,
  ISO_DATE_FORMAT,
} from "~/constants/dateFormats";
import {
  IMPORT_ACTIVITY_TEMPLATE_NAME_FORM,
  ImportsFields,
  TemplatesEntity,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import {
  ImporterActions,
  ImporterSelectors,
  withBatchImport,
  withReceipt,
} from "~/features";
import withServiceStatusBanner from "~/hocs/withServiceStatusBanner";
import {
  ImportActivityActions,
  ImportActivitySelectors,
} from "~/pages/ImportActivity/redux";
import { TemplateActions, TemplateSelectors } from "~/redux";
import { getValue } from "~/utils/object";
import { formatMessage } from "~/utils/string";

import { ImportsModels } from "../Imports/models";
import GenerateExport from "./components/GenerateExport";
import ImportActivityTable from "./components/ImportActivityTable";
import SelectTemplateCard from "./components/SelectTemplateCard";
import TestImportFile from "./components/TestImportFile";
import styles from "./ImportActivity.module.scss";

const ImportActivity = props => {
  const { shipmentTemplate, onImportOnDemand, isImportServiceAvailable } =
    props;
  const [importOnDemandRunning, setImportOnDemandRunning] =
    React.useState(false);
  const handleImportOnDemand = React.useCallback(async () => {
    try {
      setImportOnDemandRunning(true);
      await onImportOnDemand(shipmentTemplate.templateId);
    } finally {
      setImportOnDemandRunning(false);
    }
  }, [shipmentTemplate, onImportOnDemand, setImportOnDemandRunning]);

  return (
    <>
      <Main.Body>
        <Legend
          rightMessage={get(props.authUser, "user.username")}
          classes={{ container: "p-0" }}
        />
        <form>
          <Card.Stack fluid>
            <Col
              xs={12}
              md={6}
              className="d-flex flex-column justify-content-center pr-md-2 p-0"
            >
              <SelectTemplateCard
                data={props.shipmentImportTemplates}
                cardTitleClassName={styles.cardTitle}
              />
              <TestImportFile
                shipmentTemplate={props.shipmentTemplate}
                cardTitleClassName={styles.cardTitle}
                fileExtension={shipmentTemplate?.[ImportsFields.EXTENSION]}
              />
            </Col>
            <Col xs={12} md={6} className="pl-md-2 p-0">
              <GenerateExport
                isImportServiceAvailable={props.isImportServiceAvailable}
                shipmentExportTemplate={props.shipmentExportTemplate}
                onSubmit={props.onClickGenerate}
                cardTitleClassName={styles.cardTitle}
              />
            </Col>
            <Col xs={12} className="p-0">
              <ImportActivityTable
                messages={props.messages}
                onClear={props.clearImportActivity}
                cardTitleClassName={styles.cardTitle}
              />
            </Col>
          </Card.Stack>
        </form>
      </Main.Body>
      <Main.Footer className={classNames("dark", styles.footer)}>
        <Button.Toolbar>
          <Button
            variant="light"
            disabled={
              !isImportServiceAvailable ||
              shipmentTemplate?.autoImport ||
              importOnDemandRunning ||
              !props.shipmentImportTemplates.length
            }
            onClick={handleImportOnDemand}
          >
            {S.IMPORT_ON_DEMAND}
          </Button>
        </Button.Toolbar>
      </Main.Footer>
    </>
  );
};

ImportActivity.propTypes = {
  shipmentTemplate: PropTypes.object,
  isImportServiceAvailable: PropTypes.bool,
  selectedTemplateId: PropTypes.string,
  onClickImport: PropTypes.func,
  clearImportActivity: PropTypes.func,
  onClickGenerate: PropTypes.func,
  onImportOnDemand: PropTypes.func,
  shipmentExportTemplate: PropTypes.object,
  messages: PropTypes.arrayOf(PropTypes.object),
  ...propTypes,
};

ImportActivity.defaultProps = {};

export default compose(
  withOverlay,
  Banner.withBanner,
  withAppContext,
  withNotifier,
  withBatchImport,
  withAppUserPreferences,
  withLocalServiceState,
  withReceipt,
  withSnackbar,
  withServiceStatusBanner,
  connect(
    (state, { localServiceState }) => ({
      shipmentImportTemplates:
        TemplateSelectors.getShipmentImportTemplatesKeyValue(state),
      shipmentExportTemplate: TemplateSelectors.getTemplateByType(
        state,
        TEMPLATE_TYPE.SHIPMENT_EXPORT
      ),
      shipmentTemplate: TemplateSelectors.getShipmentTemplateById(
        state,
        ImportActivitySelectors.templateFormSelector(
          state,
          "shipmentTemplateId"
        )
      ),
      shipmentReceiptTemplate: TemplateSelectors.getTemplateByType(
        state,
        TEMPLATE_TYPE.SHIPMENT_RECEIPT
      ),
      initialValues: {
        shipmentTemplateId: ImportsModels.getDefaultTemplateId(
          TemplateSelectors.getShipmentImportTemplates(state)
        ),
      },
      isImportServiceAvailable: localServiceState.isRunning,
      activeTemplateId: ImporterSelectors.getActiveTemplateId(state),
      messages: ImporterSelectors.getImportMessages(state),
    }),
    (dispatch, { notifier, batchImport, overlay, snackbar, history }) => ({
      setActiveTemplateId: templateId =>
        dispatch(ImporterActions.setActiveTemplateId(templateId)),
      clearImportActivity: () =>
        dispatch(ImporterActions.clearImportMessages()),
      fetchExportTemplate: notifier.runAsync(
        () => dispatch(TemplateActions.fetchCacheableShipmentExportTemplate()),
        { entityName: S.SHIPMENT_EXPORT_TEMPLATE }
      ),
      fetchImportShipmentTemplate: notifier.runAsync(
        templateId =>
          dispatch(
            TemplateActions.fetchCacheableShipmentImportTemplateById(templateId)
          ),
        { entityName: S.TEMPLATE }
      ),
      onClickGenerate: overlay.showWhile(
        notifier.runAsync(
          async values => {
            const exportDate = moment(
              values[TemplatesEntity.DATE],
              DAY_DEFAULT_DATE_FORMAT
            ).format(ISO_DATE_FORMAT);

            const csvData = await dispatch(
              ImportActivityActions.fetchGeneratedManifest({
                exportDate,
              })
            ).catch(error => {
              if (!get(error, "errors[0].message")) {
                set(
                  error,
                  "errors[0].message",
                  S.UNABLE_TO_RETRIEVE_EXPORT_DATA
                );
              }

              throw error;
            });

            const csvString = getValue(csvData, "data.csvString");

            if (!csvString.length) {
              return snackbar.showInfo({
                message: S.NO_DATA_TO_EXPORT,
                displayTime: SHOW_ALERT_DISPLAY_TIME,
              });
            }

            const { filePath, fileName, directoryPath } = getFileAttributes(
              values[TemplatesEntity.EXPORT_FILE]
            );

            await localApi.fs
              .write(directoryPath, fileName, csvString)
              .catch(error => {
                set(error, "errors[0].message", S.CANNOT_CREATE_EXPORT_FILE);
                throw error;
              });

            return snackbar.showSuccess({
              message: formatMessage(S.GENERATED_EXPORT_FILE_$, filePath),
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          },
          { entityName: S.GENERATE_MANIFEST }
        )
      ),
      onImportOnDemand: async templateId => {
        try {
          return await batchImport.runImportJobForce(templateId);
        } catch (error) {
          if (isUnauthorizedUserError(error)) {
            history.replace({
              pathname: ROUTE.LOGOUT_PAGE,
            });
          }
        }
      },
    })
  ),
  withAppLoader({
    loadFn: async ({ fetchExportTemplate }) => fetchExportTemplate(),
  }),
  reduxForm({
    form: IMPORT_ACTIVITY_TEMPLATE_NAME_FORM,
    onChange: (values, dispatch, props) => {
      // values.shipmentTemplateId may be undefined on form init
      values.shipmentTemplateId &&
        props.fetchImportShipmentTemplate(values.shipmentTemplateId);
      props.setActiveTemplateId(values.shipmentTemplateId);
    },
  })
)(ImportActivity);

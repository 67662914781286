import { useMemo } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose, lifecycle } from "recompose";
import { Field, reduxForm } from "redux-form";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import {
  SHIPMENT_REVIEW_CHANGE_DATE_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";
import { isWeekend } from "~/utils/date";

import Modal from "../ShipmentReviewModal";
import styles from "./ChangeDateModal.module.scss";

const ChangeDateModal = ({
  onSubmit,
  enableWeekend,
  handleSubmit,
  open,
  onCancel,
}) => {
  const { minDate, maxDate } = useMemo(
    () => ShipmentModels.getAvailableDateRange(enableWeekend),
    [enableWeekend]
  );

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={S.CHANGE_DATE}
      dialogClassName={styles.changeDateModal}
      saveButton={{ visible: false }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className="p-0">
          <Row>
            <Col>
              <Field
                readOnly
                component={FormControl.DatePicker}
                label={S.SHIPMENT_DATE}
                name={ShipmentEntity.SHIPMENT_DATE}
                helperText={S.FORWARD_SHIPMENT_DATE}
                dateFormat={DEFAULT_DATE_FORMAT}
                minDate={minDate}
                maxDate={maxDate}
                filterDate={date => enableWeekend || !isWeekend(date)}
                required
              />
            </Col>
            <Col xs="auto" className={styles.buttonContainer}>
              <Button variant="primary" type="submit" className={styles.button}>
                {S.CHANGE_DATE}
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
    </Modal>
  );
};

ChangeDateModal.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  enableWeekend: PropTypes.bool,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default compose(
  reduxForm({
    form: SHIPMENT_REVIEW_CHANGE_DATE_FORM,
    initialValues: {
      [ShipmentEntity.SHIPMENT_DATE]: moment().format(DEFAULT_DATE_FORMAT),
    },
    destroyOnUnmount: false,
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (!this.props.open && prevProps.open) {
        this.props.reset();
      }
    },
  })
)(ChangeDateModal);

import { createSelector } from "reselect";

import { EUR, GB, GBP, IE } from "~/constants/strings";

export const getData = state => state.app.references;

export const getCountries = createSelector(getData, ({ countries }) =>
  countries.sort((a, b) => {
    if (a.countryKey === GB) return -1;
    if (b.countryKey === GB) return 1;
    if (a.countryKey === IE) return -1;
    if (b.countryKey === IE) return 1;

    return a.countryName > b.countryName ? 1 : -1;
  })
);

export const getCountriesKeyValue = createSelector(getCountries, countries =>
  countries.map(({ countryKey: value, countryName: label }) => ({
    label,
    value,
  }))
);

export const getOutboundServices = createSelector(
  getData,
  ({ outboundServices = [] }) => outboundServices
);

export const getExportReasons = createSelector(
  getData,
  ({ exportReasons = [] }) => exportReasons
);

export const getInboundServices = createSelector(
  getData,
  ({ inboundServices = [] }) => inboundServices
);

export const getNotifyRequired = createSelector(
  getOutboundServices,
  ({ outboundServices = [] }) => outboundServices
);

export const getCurrencies = createSelector(getData, ({ currencies = [] }) =>
  currencies.sort((a, b) => {
    if (a.currencyCode === GBP) return -1;
    if (b.currencyCode === GBP) return 1;
    if (a.currencyCode === EUR) return -1;
    if (b.currencyCode === EUR) return 1;

    return a.currencyCode > b.currencyCode ? 1 : -1;
  })
);

export const getCurrenciesKeyValue = createSelector(getCurrencies, currencies =>
  currencies.map(({ currencyCode }) => ({
    label: currencyCode,
    value: currencyCode,
  }))
);

export const getActiveOutboundService = createSelector(
  getData,
  ({ activeOutboundService }) => activeOutboundService
);

export const getActiveInboundService = createSelector(
  getData,
  ({ activeInboundService }) => activeInboundService
);

import { useMemo } from "react";

import { isEmpty } from "lodash/lang";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";
import { FormControl } from "@dpdgroupuk/mydpd-ui";

import Checkbox from "~/components/Checkbox";
import { FILE_OPTIONS, ImportsFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers } from "~/models";
import FieldImportExportFile from "~/pages/Imports/components/FieldImportExportFile/FieldImportExportFile";
import ImportCard from "~/pages/Imports/components/ImportCard";
import { ImportsModels } from "~/pages/Imports/models";
import { getValue } from "~/utils/object";
import { formatMessage } from "~/utils/string";

const FileOptions = ({
  step,
  delimiters,
  printSequence,
  profiles,
  hiddenData,
  formValues,
  shipmentImportTemplatesKeyValue,
  fetchShipmentImportTemplateById,
  onChangeCreateReceiptFile,
  onChangeOneReceiptPerShipment,
  onChangeOverrideWeightToggle,
  onClickImportTemplate,
  checkedOverrideWeightToggle,
  fieldNames,
  exportCsvLink,
  fetchUserDirectories,
  isRunning,
  fetchUserTrees,
  checkPrinterStatus,
  userTreePath,
}) => {
  const disableExport = useMemo(() => {
    const definition = getValue(formValues, ImportsFields.DEFINITION, "");
    if (definition === TEMPLATE_TYPE.SHIPMENT_RECEIPT.toString()) {
      return getValue(formValues, ImportsFields.DEFAULT_TEMPLATE, false);
    }
    if (definition === TEMPLATE_TYPE.SHIPMENT.toString()) {
      return isEmpty(shipmentImportTemplatesKeyValue);
    }
  }, [
    formValues[ImportsFields.DEFINITION],
    formValues[ImportsFields.DEFAULT_TEMPLATE],
    shipmentImportTemplatesKeyValue,
  ]);

  return (
    <ImportCard
      step={step}
      help={ImportsModels.IMPORTS_HELP[FILE_OPTIONS]}
      title={S.FILE_OPTIONS}
      helpModalTitle={S.FILE_OPTIONS}
    >
      <Row>
        {!hiddenData.workDirectory && (
          <Col sm={4} md={3}>
            <Field
              component={FormControl.Input}
              label={fieldNames.workDirectory.label}
              name={fieldNames.workDirectory.name}
              maxLength={200}
              helperText={formatMessage(S.MAX_$_CHARACTERS, 200)}
              required
              type="directory"
              initialTreePath={userTreePath}
              getUserDirectories={fetchUserDirectories}
              getUserTrees={fetchUserTrees}
              checkPrinterStatus={checkPrinterStatus}
              folderError={
                !isRunning && {
                  message: S.FAILED_TO_CONNECT_WITH_APP,
                }
              }
            />
          </Col>
        )}
        {!hiddenData.extension && (
          <Col sm={4} md={3}>
            <Field
              component={FormControl.Input}
              label={fieldNames.extension.label}
              name={fieldNames.extension.name}
              maxLength={4}
              helperText={formatMessage(S.MAX_$_CHARACTERS, 4)}
              required
            />
          </Col>
        )}
        {!hiddenData.delimiter && (
          <Col sm={4} md={3}>
            <Field
              component={FormControl.Dropdown}
              label={fieldNames.delimiter.label}
              name={fieldNames.delimiter.name}
              values={delimiters}
              helperText
              required
              textTransform={S.UPPERCASE}
            />
          </Col>
        )}
        {!hiddenData.productDelimiter && (
          <Col sm={4} md={3}>
            <Field
              component={FormControl.Dropdown}
              label={fieldNames.productDelimiter.label}
              name={fieldNames.productDelimiter.name}
              values={delimiters}
              helperText
              required={!hiddenData.productDelimiter}
              textTransform={S.UPPERCASE}
            />
          </Col>
        )}
        {!hiddenData[ImportsFields.EXPORT_FILE_BEHAVIOR] && (
          <Col sm={4} md={3}>
            <Field
              component={FormControl.Dropdown}
              label={S.EXPORT_FILE_BEHAVIOR}
              name={ImportsFields.EXPORT_FILE_BEHAVIOR}
              values={S.BEHAVIOR_EXPORT_FILE}
              textTransform={S.UPPERCASE}
              required
            />
          </Col>
        )}

        {!hiddenData.shipmentFields && (
          <Col sm={4} md={3}>
            <Field
              component={Checkbox}
              label={S.PRINT_LABEL_ON_IMPORT}
              name={ImportsFields.AUTO_PRINT}
              checked={formValues[ImportsFields.AUTO_PRINT]}
            />
          </Col>
        )}
        {!hiddenData[ImportsFields.PRINT_SEQUENCE] && (
          <Col sm={4} md={3}>
            <Field
              component={FormControl.Dropdown}
              label={S.SELECT_PRINTING_SEQUENCE}
              name={ImportsFields.PRINT_SEQUENCE}
              values={printSequence}
              textTransform={S.UPPERCASE}
            />
          </Col>
        )}
        {!hiddenData.shipmentFields && (
          <>
            <Col sm={4} md={3}>
              <Field
                component={Checkbox}
                label={S.DISABLE_BAD_LABELS}
                name={ImportsFields.DISABLE_BAD_LABELS}
                checked={formValues[ImportsFields.DISABLE_BAD_LABELS]}
              />
            </Col>
            <Col sm={4} md={3}>
              <Field
                component={Checkbox}
                label={S.AUTO_IMPORT}
                name={ImportsFields.AUTO_IMPORT}
                checked={formValues[ImportsFields.AUTO_IMPORT]}
              />
            </Col>
            <Col sm={4} md={3}>
              <Field
                component={FormControl.Input}
                normalize={Normalizers.numbersNormalize}
                label={S.HEADER_LINES}
                name={ImportsFields.HEADER_LINES}
                helperText
                required
                maxLength={2}
              />
            </Col>
            <Col sm={4} md={3}>
              <Field
                component={FormControl.Input}
                normalize={Normalizers.numbersNormalize}
                label={S.FOOTER_LINES}
                name={ImportsFields.FOOTER_LINES}
                helperText
                required
                maxLength={2}
              />
            </Col>
          </>
        )}
        {hiddenData[ImportsFields.WEIGHT_OVERRIDE_TOGGLE] && (
          <Col sm={4} md={3}>
            <Field
              component={Checkbox}
              label={S.WEIGHT_OVERRIDE_CHECKBOX}
              name={ImportsFields.WEIGHT_OVERRIDE_TOGGLE}
              checked={checkedOverrideWeightToggle}
              onChange={onChangeOverrideWeightToggle}
            />
          </Col>
        )}
        {!hiddenData[ImportsFields.OVERRIDE_WEIGHT] && (
          <Col sm={4} md={3}>
            <Field
              component={FormControl.Input}
              normalize={Normalizers.numbersNormalize}
              label={S.OVERRIDE_WEIGHT}
              name={ImportsFields.OVERRIDE_WEIGHT}
              helperText
              required
              maxLength={2}
            />
          </Col>
        )}
      </Row>
      <Row>
        {!hiddenData[ImportsFields.PROFILE_CODE] && (
          <Col sm={4} md={3}>
            <Field
              component={FormControl.Dropdown}
              label={S.TEMPLATE_PROFILE}
              name={ImportsFields.PROFILE_CODE}
              values={profiles}
              textTransform={S.UPPERCASE}
            />
          </Col>
        )}
        {!hiddenData[ImportsFields.DEFAULT_TEMPLATE] && (
          <Col sm={4} md={3}>
            <Field
              component={Checkbox}
              label={S.DEFAULT_TEMPLATE}
              name={ImportsFields.DEFAULT_TEMPLATE}
              checked={formValues[ImportsFields.DEFAULT_TEMPLATE]}
            />
          </Col>
        )}
        {!hiddenData[ImportsFields.TEMPLATE_NAME] && (
          <Col sm={4} md={3}>
            <Field
              component={FormControl.Dropdown}
              label={S.TEMPLATES}
              name={ImportsFields.TEMPLATE_NAME}
              values={shipmentImportTemplatesKeyValue}
              onChange={(event, value) =>
                fetchShipmentImportTemplateById(value)
              }
              textTransform={S.NONE}
              onBlur={event => {
                event.preventDefault();
              }}
            />
          </Col>
        )}
      </Row>
      {!hiddenData[ImportsFields.CREATE_RECEIPT] && (
        <Row>
          <Col sm={4} md={3}>
            <Field
              component={Checkbox}
              label={S.CREATE_RECEIPT_FILE}
              name={ImportsFields.CREATE_RECEIPT}
              onChange={onChangeCreateReceiptFile}
              checked={formValues[ImportsFields.CREATE_RECEIPT]}
            />
          </Col>
          {!hiddenData[ImportsFields.ONE_RECEIPT_PER_SHIPMENT] && (
            <>
              <Col sm={4} md={3}>
                <Field
                  component={Checkbox}
                  label={S.ONE_RECEIPT_PER_SHIPMENT}
                  name={ImportsFields.ONE_RECEIPT_PER_SHIPMENT}
                  onChange={onChangeOneReceiptPerShipment}
                  checked={formValues[ImportsFields.ONE_RECEIPT_PER_SHIPMENT]}
                />
              </Col>
              <Col sm={4} md={3}>
                <Field
                  component={Checkbox}
                  label={S.ONE_LINE_PER_SHIPMENT}
                  name={ImportsFields.ONE_LINE_PER_SHIPMENT}
                  disabled={!formValues[ImportsFields.ONE_RECEIPT_PER_SHIPMENT]}
                  checked={formValues[ImportsFields.ONE_LINE_PER_SHIPMENT]}
                />
              </Col>
              <Col sm={4} md={3}>
                <Field
                  component={Checkbox}
                  label={S.USE_REFERENCE_FIVE}
                  name={ImportsFields.USE_REFERENCE_FIVE}
                  disabled={!formValues[ImportsFields.ONE_RECEIPT_PER_SHIPMENT]}
                  checked={formValues[ImportsFields.USE_REFERENCE_FIVE]}
                />
              </Col>
            </>
          )}
        </Row>
      )}
      {!hiddenData.import && (
        <FieldImportExportFile
          key={formValues[ImportsFields.DEFINITION]} // hack for clearing native input type file if we change definition from shipment to shipment receipt or vise versa
          onClickImportTemplate={onClickImportTemplate}
          exportCsvLink={exportCsvLink}
          disableExport={disableExport}
        />
      )}
    </ImportCard>
  );
};

FileOptions.propTypes = {
  step: PropTypes.number,
  profiles: PropTypes.array,
  delimiters: PropTypes.array,
  printSequence: PropTypes.array,
  shipmentImportTemplatesKeyValue: PropTypes.array,
  hiddenData: PropTypes.object,
  formValues: PropTypes.object,
  fetchShipmentImportTemplateById: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChangeCreateReceiptFile: PropTypes.func,
  onChangeOneReceiptPerShipment: PropTypes.func,
  onChangeOverrideWeightToggle: PropTypes.func,
  onClickImportTemplate: PropTypes.func,
  checkedOverrideWeightToggle: PropTypes.bool,
  fieldNames: PropTypes.object,
  exportCsvLink: PropTypes.string,
  userTreePath: PropTypes.string,
  fetchUserDirectories: PropTypes.func,
  fetchUserTrees: PropTypes.func,
  checkPrinterStatus: PropTypes.func,
  isRunning: PropTypes.bool,
};

export default FileOptions;

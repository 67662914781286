import { useCallback, useEffect, useMemo, useState } from "react";

import Joi from "joi";
import get from "lodash/get";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useColumnOrder } from "react-table/src/plugin-hooks/useColumnOrder";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import { useResizeColumns } from "react-table/src/plugin-hooks/useResizeColumns";
import { useRowSelect } from "react-table/src/plugin-hooks/useRowSelect";
import { compose, lifecycle, withHandlers } from "recompose";
import { touch } from "redux-form";

import {
  UserDataActions,
  UserDataSelectors,
  UserSessionDataActions,
  UserSessionDataSelectors,
  useUserHeaderStatePersist,
  withAppContext,
  withAppUserPreferences,
  withLocalServiceState,
  withUrlResolver,
} from "@dpdgroupuk/mydpd-app";
import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";
import {
  Banner,
  Card,
  DndTable,
  Legend,
  Main,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import {
  SHIPMENT_REVIEW_CHANGE_DATE_FORM,
  SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM,
  SHIPMENT_REVIEW_SEARCH_FORM,
  ShipmentReviewFilterFields,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import useToggle from "~/hooks/useToggle";
import shipmentReview from "~/models/validators/shipmentReview";
import { AuthSelectors, UmsSelectors } from "~/redux";
import * as routes from "~/router";
import { toUppercaseValues } from "~/utils/object";
import {
  getInitialPageQuery,
  getQueryFilters,
  getQueryPagination,
  isPristineForm,
  parseQuery,
  stringifyQuery,
} from "~/utils/query";

import ActionButtons from "../../components/ActionButtons";
import ChangeDateModal from "../../components/ChangeDateModal";
import Filters from "../../components/Filters";
import PrintManifestModal from "../../components/PrintManifestModal";
import PrintUnprintedModal from "../../components/PrintUnprintedModal";
import withShipmentListUpdateActions from "../../hocs/withShipmentListUpdateActions";
import {
  getExportShipmentsCsvHref,
  getPermissionsForSelectedShipments,
  SHIPMENT_FLAGS,
  ShipmentListPropTypes,
} from "../../models";
import { ShipmentReviewSelectors } from "../../redux";
import { DEFAULT_SHIPMENT_COLUMNS } from "./constants";
import {
  ALWAYS_VISIBLE_SHIPMENT_COLUMNS,
  getShipmentActionColumns,
  getShipmentReviewColumns,
  MAP_COLUMN_NAMES_FOR_EXPORT,
} from "./models";
import styles from "./ShipmentList.module.scss";

const ShipmentList = props => {
  const [selectedShipments, setSelectedShipments] = useState([]);
  const customerPrefs = get(props, "customerPrefs");
  const user = get(props, "authUser.user");

  const [actionPermissions, setActionPermissions] = useState(
    SHIPMENT_FLAGS.INITIAL
  );

  const { page, pageSize, filters, isPristine } = useMemo(
    () => ({
      ...getQueryPagination(props.location.search),
      filters: {
        [ShipmentReviewFilterFields.SEARCH_DATE]:
          getInitialPageQuery().searchDate,
        ...toUppercaseValues(
          getQueryFilters(
            props.location.search,
            ShipmentReviewFilterFields,
            false
          )
        ),
      },
      isPristine: isPristineForm(
        props.location.search,
        ShipmentReviewFilterFields,
        false
      ),
    }),
    [props.location]
  );

  const changeDateModalToggle = useToggle();
  const printUnprintedToggle = useToggle();
  const printManifestToggle = useToggle();

  const handleChangeDateClick = useCallback(
    ({ shipmentDate }) =>
      props.onClickChangeDate(
        selectedShipments,
        shipmentDate,
        changeDateModalToggle.switchOff
      ),
    [selectedShipments]
  );

  useEffect(() => {
    const permissions = getPermissionsForSelectedShipments(selectedShipments);
    setActionPermissions(permissions);
  }, [selectedShipments]);

  const handleClickVoid = useCallback(() => {
    props.onClickVoid(selectedShipments);
  }, [selectedShipments]);

  const handleClickUnvoid = useCallback(() => {
    props.onClickUnvoid(selectedShipments);
  }, [selectedShipments]);

  const handleClickDelete = useCallback(() => {
    props.onClickDelete(selectedShipments);
  }, [selectedShipments]);

  const handleClickPrintShipment = useCallback(() => {
    props.onShipmentPrint(selectedShipments);
  }, [selectedShipments]);

  const onViewShipmentClick = useCallback(shipmentId => () => {
    props.history.push(`review/${shipmentId}`);
  });

  const onEditShipmentClick = useCallback(shipmentId => () => {
    props.history.push(`review/${shipmentId}/edit`);
  });

  const onChangeColumnsSelection = useCallback(
    value => {
      if (value.length) {
        props.setSelectedColumnNames(value);
      }
    },
    [props.setSelectedColumnNames]
  );

  const columnsForExport = useMemo(
    () =>
      props.selectedColumnNames
        .map(value => MAP_COLUMN_NAMES_FOR_EXPORT[value])
        .filter(v => v),
    [props.selectedColumnNames]
  );

  const printUnprintedLabels = useCallback(async values => {
    const printed = await props.printUnprintedLabels(values);
    printUnprintedToggle.switchOff();
    isBoolean(printed) && printed && props.reloadFn();
  });

  const closePrintManifest = useCallback(async reload => {
    printManifestToggle.switchOff();
    isBoolean(reload) && reload && props.reloadFn();
  });

  const [data, totalCount] = useMemo(
    () => [
      get(props, "shipments.results", []),
      get(props, "shipments.totalResults", 0),
    ],
    [props.shipments]
  );
  const columns = useMemo(
    () => [
      ...getShipmentReviewColumns(props),
      ...getShipmentActionColumns(
        onViewShipmentClick,
        onEditShipmentClick,
        customerPrefs,
        user
      ),
    ],
    [customerPrefs, user]
  );

  const hiddenColumns = useMemo(() =>
    columns.reduce((acc, column) => {
      if (!props.selectedColumnNames.includes(column.accessor)) {
        acc.push(column.accessor);
      }
      return acc;
    }, [])
  );

  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  return (
    <>
      <Main.Body>
        <Legend
          rightMessage={get(user, "username")}
          classes={{ container: "p-0" }}
        />
        <Card.Stack fluid>
          <Col className="p-0">
            <Card>
              <Card.Title className="mb-4">{S.SHIPMENT_REVIEW}</Card.Title>
              <Row>
                <Col sm={12}>
                  <Filters
                    form={SHIPMENT_REVIEW_SEARCH_FORM}
                    initialValues={filters}
                    isPristine={isPristine}
                    onClear={props.onClear}
                    errors={props.errors}
                    onSubmit={props.onSearch}
                    onClearShipmentDate={props.onClearShipmentDate}
                  />
                </Col>
                <Col sm={12}>
                  <DndTable
                    data={data}
                    columns={columns}
                    classes={{
                      container: styles.tableContainer,
                      table: styles.table,
                      popover: styles.popover,
                    }}
                    tableHooks={[
                      useFlexLayout,
                      useRowSelect,
                      useResizeColumns,
                      useColumnOrder,
                      useUserHeaderStatePersist,
                    ]}
                    unhiddenColumns={ALWAYS_VISIBLE_SHIPMENT_COLUMNS}
                    initialState={{
                      hiddenColumns,
                      columnOrder: props.selectedColumnNames,
                      storageKey: "shipmentColumns",
                    }}
                    onChangeColumnsSelection={onChangeColumnsSelection}
                    onColumnOrderChanged={onChangeColumnsSelection}
                    isVisiblePopover
                    stretchColumnWidth={false}
                    defaultColumn={defaultColumn}
                    onDoubleClickRow={(e, row) =>
                      onViewShipmentClick(row.original[S.ROW_ID_FIELD_NAME])()
                    }
                    getSelectedRows={setSelectedShipments}
                    selectable
                  >
                    <DndTable.DndPaginator
                      page={page}
                      totalCount={totalCount}
                      pageSize={pageSize}
                      statusText={S.SHOWING}
                      onPrevious={props.onPrevious}
                      onNext={props.onNext}
                      onFirst={props.onFirst}
                      onLast={props.onLast}
                    />
                  </DndTable>
                </Col>
              </Row>
            </Card>
          </Col>
        </Card.Stack>
      </Main.Body>
      <Main.Footer className="dark">
        <ActionButtons
          sendToFileHref={props.getSendToFileHref(columnsForExport)}
          onClickChangeDate={changeDateModalToggle.switchOn}
          onClickVoid={handleClickVoid}
          onClickUnvoid={handleClickUnvoid}
          onClickDelete={handleClickDelete}
          onClickPrintShipment={handleClickPrintShipment}
          onClickPrintUnprinted={printUnprintedToggle.switchOn}
          onClickPrintManifest={printManifestToggle.switchOn}
          noShipmentsSelected={isEmpty(selectedShipments)}
          actionPermissions={actionPermissions}
        />
      </Main.Footer>

      <ChangeDateModal
        onSubmit={handleChangeDateClick}
        enableWeekend={props.enableWeekend}
        open={changeDateModalToggle.value}
        onCancel={changeDateModalToggle.switchOff}
      />
      <PrintUnprintedModal
        open={printUnprintedToggle.value}
        onCancel={printUnprintedToggle.switchOff}
        onSubmit={printUnprintedLabels}
        enableWeekend={props.enableWeekend}
      />
      <PrintManifestModal
        open={printManifestToggle.value}
        onCancel={closePrintManifest}
      />
    </>
  );
};

ShipmentList.propTypes = ShipmentListPropTypes;

export default compose(
  withAppContext,
  withUrlResolver,
  withAppUserPreferences,
  Banner.withBanner,
  withSnackbar,
  withLocalServiceState,
  connect(
    (state, { localServiceState, location, app }) => ({
      uiFields: [
        ...ShipmentReviewSelectors.getChangeDateField(state, {
          formName: SHIPMENT_REVIEW_CHANGE_DATE_FORM,
        }),
        ...ShipmentReviewSelectors.getChangeDateField(state, {
          formName: SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM,
        }),
      ],
      selectedColumnNames:
        UserDataSelectors.getItem(state.app, "shipmentColumns") ||
        DEFAULT_SHIPMENT_COLUMNS,
      shipments: ShipmentReviewSelectors.getShipmentList(state),
      enableWeekend: AuthSelectors.getEnableWeekend(state),
      errors: ShipmentReviewSelectors.getShipmentSearchFormSyncErrors(state),
      customerPrefs: UmsSelectors.getCustomerPrefs(state),
      securitySettings: UmsSelectors.getSecuritySettings(state),
      getSendToFileHref: columns =>
        getExportShipmentsCsvHref(
          app.apis.getApisBaseUrl(),
          location.search,
          columns
        ),
      isImportServiceAvailable: localServiceState.isRunning,
      queryFromStorage: UserSessionDataSelectors.getDataItem(
        "shipmentListSearchQuery"
      )(state),
    }),
    (dispatch, { history, location }) => {
      const getModifiedQueryParams = newQuery => {
        const page = getQueryPagination(location.search);
        const query = getQueryFilters(
          location.search,
          ShipmentReviewFilterFields,
          false
        );

        return stringifyQuery(
          {
            ...page,
            ...query,
            ...newQuery,
          },
          true,
          false
        );
      };

      const changeFilter = (newQuery, reload = true) => {
        history.push({
          pathname: routes.REVIEW,
          search: getModifiedQueryParams(newQuery),
          state: {
            reload,
          },
        });
      };

      const replaceFilter = (newQuery, reload = true) => {
        history.replace({
          pathname: routes.REVIEW,
          search: getModifiedQueryParams(newQuery),
          state: {
            reload,
          },
        });
      };

      const onNextOrPrev = page => {
        changeFilter({ page });
      };

      const touchErrorFields = (formName, fields) => {
        dispatch(touch(formName, fields));
      };

      return {
        onNext: onNextOrPrev,
        onPrevious: onNextOrPrev,
        onFirst: onNextOrPrev,
        onLast: onNextOrPrev,
        dispatch,
        changeFilter,
        replaceFilter,
        setSelectedColumnNames: columns =>
          dispatch(UserDataActions.setItem("shipmentColumns", columns)),
        touchErrorFields,
      };
    }
  ),
  withShipmentListUpdateActions,
  withHandlers({
    onClear:
      ({ changeFilter }) =>
      () =>
        changeFilter(getInitialPageQuery()),
    onSearch:
      ({ changeFilter }) =>
      formValues =>
        changeFilter({ ...getInitialPageQuery(), ...formValues }),
    printUnprintedLabels:
      ({ printUnprintedLabels }) =>
      async values =>
        await printUnprintedLabels(values.shipmentDate),
    onClearShipmentDate:
      ({ changeFilter }) =>
      formValues =>
        changeFilter({
          ...getInitialPageQuery(),
          ...formValues,
          searchDate: null,
        }),
  }),
  lifecycle({
    async componentDidMount() {
      const {
        location,
        queryFromStorage,
        dispatch,
        touchErrorFields,
        reloadFn,
      } = this.props;

      // TODO: Refactor to reduce code duplication: src\pages\FailedImports\pages\FailedImportsList\FailedImportsList.js
      const currentSearch = location.search;
      const storageSearchQuery = parseQuery(queryFromStorage);
      const { searchCriteria = "" } = parseQuery(currentSearch);
      const errors = Joi.validate({ searchCriteria }, shipmentReview);
      const details = errors?.error?.details;

      if (!storageSearchQuery.searchDate && !currentSearch) {
        storageSearchQuery.searchDate = getInitialPageQuery().searchDate;
        await dispatch(
          UserSessionDataActions.setItem(
            "shipmentListSearchQuery",
            stringifyQuery(storageSearchQuery)
          )
        );
      }

      if (Array.isArray(details) && details.length) {
        const fields = details
          .map(f => f.path)
          .filter(Boolean)
          .flat(Infinity);
        touchErrorFields(SHIPMENT_REVIEW_SEARCH_FORM, fields);
      } else {
        // otherwise fetch data
        reloadFn(currentSearch || stringifyQuery(storageSearchQuery));
      }

      if (currentSearch) {
        dispatch(
          UserSessionDataActions.setItem(
            "shipmentListSearchQuery",
            currentSearch
          )
        );
      }
    },
    componentDidUpdate(prevProps) {
      const { location, queryFromStorage, dispatch, reloadFn, replaceFilter } =
        this.props;

      const currentSearch = location.search;

      if (prevProps.location.search !== currentSearch) {
        if (currentSearch && queryFromStorage !== currentSearch) {
          dispatch(
            UserSessionDataActions.setItem(
              "shipmentListSearchQuery",
              currentSearch
            )
          );
        }

        if (get(location, "state.reload", true)) {
          reloadFn();
        }
      }

      if (!currentSearch?.length && queryFromStorage) {
        replaceFilter(queryString.parse(queryFromStorage), false);
      }
    },
    componentWillUnmount() {
      this.props.banner.hideByType(BANNERS_TYPES.SUCCESS);
    },
  })
)(ShipmentList);

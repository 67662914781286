import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Card } from "@dpdgroupuk/mydpd-ui";

const ImportCard = ({
  step,
  title,
  helpModalTitle,
  children,
  help,
  footer,
  closeButtonText,
  confirmButtonText,
  confirmButtonVariant,
  closeButtonVariant,
  classes,
  rounded,
}) => (
  <Card
    className={classNames(classes.container, "mydpd-card-step")}
    rounded={rounded}
  >
    <Card.Header className={classes.header}>
      <Col className="w-100 pl-0">
        <Card.StepTitle
          className={classes.title}
          step={step}
          title={title}
          textClassName={classes.text}
        />
      </Col>
      <Col className="col-auto">
        <Row className="align-items-center">
          {_.isArray(help) && (
            <Card.HelpIcon
              help={help}
              helpModalTitle={helpModalTitle}
              closeButtonText={closeButtonText}
              confirmButtonText={confirmButtonText}
              confirmButtonVariant={confirmButtonVariant}
              closeButtonVariant={closeButtonVariant}
            />
          )}
        </Row>
      </Col>
    </Card.Header>
    {children}
    {footer && (
      <Card.Footer
        className={classNames("mydpd-card-step-footer", classes.footer)}
      >
        <Container fluid>
          <Row>
            <Col
              className={classNames("col-12 text-right", classes.footerContent)}
            >
              {footer}
            </Col>
          </Row>
        </Container>
      </Card.Footer>
    )}
  </Card>
);
ImportCard.defaultProps = {
  classes: {},
  rounded: true,
};

ImportCard.propTypes = {
  step: PropTypes.number,
  title: PropTypes.string,
  helpModalTitle: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  complete: PropTypes.bool,
  isLoading: PropTypes.bool,
  help: PropTypes.array,
  footer: PropTypes.elementType,
  closeButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonVariant: PropTypes.string,
  closeButtonVariant: PropTypes.string,
  rounded: PropTypes.bool,
};

export default ImportCard;

import {
  createActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

export const NAMESPACE = "APP/IMPORT";

export const ActionTypes = createActionTypes(NAMESPACE, {
  ADD_IMPORT_MESSAGE: "ADD_IMPORT_MESSAGE",
  ADD_IMPORT_PROGRESS: "ADD_IMPORT_PROGRESS",
  CLEAR_IMPORT_MESSAGES: "CLEAR_IMPORT_MESSAGES",
  SET_ACTIVE_TEMPLATE_ID: "SET_ACTIVE_TEMPLATE_ID",
  SET_RUNNING_STATE: "SET_RUNNING_STATE",
});

export const setActiveTemplateId = templateId =>
  createPayloadAction(ActionTypes.SET_ACTIVE_TEMPLATE_ID, templateId);

export const setRunningState = (flag, value) =>
  createPayloadAction(ActionTypes.SET_RUNNING_STATE, { flag, value });

export const addImportMessage = message =>
  createPayloadAction(ActionTypes.ADD_IMPORT_MESSAGE, message);

export const addImportProgressMessage = message =>
  createPayloadAction(ActionTypes.ADD_IMPORT_PROGRESS, message);

export const clearImportMessages = () =>
  createPayloadAction(ActionTypes.CLEAR_IMPORT_MESSAGES);

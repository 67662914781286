import { EventLogger, LOG_LEVEL } from "@dpdgroupuk/mydpd-app";

export const getEntryRow = entry => entry.row + 1;

export const processImportResults = (
  jobId,
  result,
  { shipmentTemplate, shippingSettings }
) => {
  const importIgnoreHeader = shipmentTemplate.ignoreHeader;
  const importHeaderLines = shipmentTemplate.headerLines;

  let errorLineNumber =
    (importIgnoreHeader && importHeaderLines) || importHeaderLines
      ? 1 + (importHeaderLines || 1)
      : 1;

  return result.entries.reduce(
    (acc, entry) => {
      const warning = entry.shipmentId && entry.failureReasons.length;
      const failed = !entry.shipmentId && !entry.success;
      entry.failureReasons.forEach(failureReason => {
        if (warning) {
          acc.warnings.push(
            EventLogger.logEntry(
              LOG_LEVEL.WARNING,
              `${jobId}: ${failureReason} on line ${getEntryRow(entry)}`,
              {
                entry,
                failureReason,
              }
            )
          );
        } else if (failed) {
          let errorMessage = `${jobId}: ${failureReason} on line ${getEntryRow(
            entry
          )}`;
          if (
            !failureReason.includes("Discarded") &&
            !shippingSettings.onScreenImportFix
          ) {
            errorMessage += " (line " + errorLineNumber + " in error file)";
          }
          acc.errors.push(
            EventLogger.logEntry(LOG_LEVEL.ERROR, errorMessage, {
              entry,
              failureReason,
            })
          );
          errorLineNumber++;
        }
      });
      return acc;
    },
    {
      warnings: [],
      errors: [],
    }
  );
};

export const groupImportLines = (lines, options = {}) => {
  options = {
    limit: 100,
    ...options,
  };

  const linesLength = lines.length;
  let rows = [];
  const groups = [];

  lines.forEach((line, key) => {
    const nextLine = lines[key + 1];

    if (line.trim() !== "") {
      rows.push(line);
    }

    if (
      (!nextLine || nextLine.indexOf("PRD") !== 0) &&
      ((rows.length % options.limit === 0 && rows.length !== 0) ||
        rows.length > options.limit)
    ) {
      groups.push(rows);
      rows = [];
    } else if (key === linesLength - 1 && rows.length !== 0) {
      groups.push(rows);
    }
  });

  return groups;
};

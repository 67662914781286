import get from "lodash/get";

import { DPD_DIRECT_SERVICE } from "~/constants/strings";

import * as routes from "./constants";

export const isRestrictedTab = (settings, tabName) => {
  const disableAdhocEditShipment = !!get(
    settings,
    "securitySettings.disableAdhocEditShipment",
    false
  );

  const disableContainerTab = !get(
    settings,
    "customerServices.services",
    []
  ).includes(DPD_DIRECT_SERVICE);

  if (tabName === routes.CREATE) {
    return disableAdhocEditShipment;
  }

  // @see https://it.dpduk.live/version/customer-shipping/sprint-2.10/diag_P4UctkGGAqCIeGQ_.html?id=1671540247392
  if (tabName === routes.SCANNING) {
    return (
      !get(settings, "shippingSettings.enableScanningScreen", false) ||
      disableAdhocEditShipment
    );
  }

  if (tabName === routes.CONTAINER) {
    return disableContainerTab;
  }

  return (
    (tabName === routes.FAILED_IMPORTS ||
      tabName === routes.FAILED_IMPORT_EDIT) &&
    !get(settings, "shippingSettings.onScreenImportFix", false)
  );
};

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { manifestApi, shipmentApi } from "~/apis";

export const ActionTypes = createActionTypes("SHIPMENT", {
  FETCH: createAsyncActionTypes("FETCH"),
  VOID: createAsyncActionTypes("VOID"),
  UNVOID: createAsyncActionTypes("UNVOID"),
  VOID_PARCELS: createAsyncActionTypes("VOID_PARCELS"),
  UNVOID_PARCELS: createAsyncActionTypes("UNVOID_PARCELS"),
  UPDATE_DATE: createAsyncActionTypes("UPDATE_DATE"),
  DELETE: createAsyncActionTypes("DELETE"),
  UPDATE_PARCELS: createAsyncActionTypes("UPDATE_PARCELS"),
  CLEAR: "CLEAR",
});

export const ManifestActionTypes = createActionTypes("MANIFEST", {
  FETCH: createAsyncActionTypes("FETCH"),
  CREATE: createAsyncActionTypes("CREATE"),
  REPRINT: createAsyncActionTypes("REPRINT"),
});

export const fetchShipments = createAsyncAction(
  query => shipmentApi.getShipmentList(query),
  ActionTypes.FETCH
);

export const clearShipments = () => ({
  type: ActionTypes.CLEAR,
});

export const voidShipment = createAsyncAction(
  (shipmentId, options) => shipmentApi.voidShipment(shipmentId, options),
  ActionTypes.VOID
);

export const voidParcels = createAsyncAction(
  (shipmentId, options) => shipmentApi.voidParcels(shipmentId, options),
  ActionTypes.VOID_PARCELS
);

export const unvoidParcels = createAsyncAction(
  (shipmentId, options) => shipmentApi.unvoidParcels(shipmentId, options),
  ActionTypes.UNVOID_PARCELS
);

export const unvoidShipment = createAsyncAction(
  (shipmentId, options) => shipmentApi.unvoidShipment(shipmentId, options),
  ActionTypes.UNVOID
);

export const updateDateShipment = createAsyncAction(
  (shipmentId, options) => shipmentApi.updateShipmentDate(shipmentId, options),
  ActionTypes.UPDATE_DATE
);

export const deleteShipment = createAsyncAction(
  (shipmentId, options) => shipmentApi.deleteShipment(shipmentId, options),
  ActionTypes.DELETE
);

export const fetchManifests = createAsyncAction(
  query => manifestApi.getManifests(query),
  ManifestActionTypes.FETCH
);

export const createManifest = createAsyncAction(
  query => manifestApi.createManifest(query),
  ManifestActionTypes.CREATE
);

export const getManifestById = createAsyncAction(
  manifestId => manifestApi.getManifestById(manifestId),
  ManifestActionTypes.REPRINT
);

export const updateShipmentParcels = createAsyncAction(
  (shipmentId, query) => shipmentApi.updateShipmentParcels(shipmentId, query),
  ActionTypes.UPDATE_PARCELS
);

import { noop } from "lodash/fp";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import {
  Banner,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import withPrint from "~/hocs/withPrint";
import { ShipmentActions } from "~/pages/Shipment/redux";
import { formatISODate } from "~/utils/date";
import { getErrorMessage, isIgnoredError } from "~/utils/error";
import { formatMessage } from "~/utils/string";

import { ShipmentReviewActions } from "../redux";

export default compose(
  Banner.withBanner,
  withSnackbar,
  withPrompt,
  withOverlay,
  withNotifier,
  withPrint,
  connect(null, (dispatch, { snackbar, overlay }) => {
    const fetchShipmentById = shipmentId =>
      dispatch(ShipmentActions.getShipmentById(shipmentId));

    const reloadFn = async (shipmentId, omitError) => {
      try {
        overlay.show();

        await fetchShipmentById(shipmentId);
      } catch (e) {
        if (!isIgnoredError(e) && e.errors[0].message !== omitError.message) {
          snackbar.showAlert({
            message: getErrorMessage(e, S.SHIPMENT),
            displayTime: SHOW_ALERT_DISPLAY_TIME,
          });
        }
      } finally {
        overlay.hide();
      }
    };

    return {
      reloadFn,
      voidShipment: shipmentId =>
        dispatch(ShipmentReviewActions.voidShipment(shipmentId)),
      unvoidShipment: shipmentId =>
        dispatch(ShipmentReviewActions.unvoidShipment(shipmentId)),
      deleteShipment: shipmentId =>
        dispatch(ShipmentReviewActions.deleteShipment(shipmentId)),
      changeShipmentDate: (shipmentId, shipmentDate) =>
        dispatch(
          ShipmentReviewActions.updateDateShipment(shipmentId, {
            shipmentDate,
          })
        ),
      voidParcels: (shipmentId, parcelNumber) =>
        dispatch(ShipmentReviewActions.voidParcels(shipmentId, parcelNumber)),
      unvoidParcels: (shipmentId, parcelNumber) =>
        dispatch(ShipmentReviewActions.unvoidParcels(shipmentId, parcelNumber)),
    };
  }),
  withHandlers({
    onClickVoid: ({ prompt, overlay, notifier, reloadFn, voidShipment }) =>
      notifier.runAsync(
        async shipment => {
          try {
            overlay.show();

            await voidShipment(shipment.shipmentId);
            await reloadFn(shipment.shipmentId);

            prompt.showInfo({
              header: S.SHIPMENT_VOIDED,
              message: formatMessage(
                S.$_SHIPMENT_VOIDED_MESSAGE,
                get(shipment, "outboundConsignment.consignmentNumber")
              ),
            });
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onClickVoidParcels: ({
      prompt,
      overlay,
      notifier,
      reloadFn,
      voidParcels,
    }) =>
      notifier.runAsync(
        async (shipment, selectedParcels = []) => {
          const parcelNumber = selectedParcels.reduce(
            (acc, { parcelNumber, isVoided }) => {
              !isVoided && acc.push(parcelNumber);
              return acc;
            },
            []
          );
          try {
            overlay.show();
            await voidParcels(shipment.shipmentId, {
              parcelNumber,
            });
            await reloadFn(shipment.shipmentId);

            const parcelNumbersString = parcelNumber.join(", ");

            const successPromptContent =
              parcelNumber.length > 1
                ? {
                    header: S.PARCELS_VOIDED,
                    message: formatMessage(
                      S.$_PARCELS_VOIDED_MESSAGE,
                      parcelNumbersString
                    ),
                  }
                : {
                    header: S.PARCEL_VOIDED,
                    message: formatMessage(
                      S.$_PARCEL_VOIDED_MESSAGE,
                      parcelNumber
                    ),
                  };

            prompt.showInfo(successPromptContent);
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.VOID_PARCELS }
      ),
    onClickUnvoidParcels: ({
      prompt,
      overlay,
      notifier,
      reloadFn,
      unvoidParcels,
    }) =>
      notifier.runAsync(
        async (shipment, selectedParcels = []) => {
          const parcelNumber = selectedParcels.reduce(
            (acc, { parcelNumber, isVoided }) => {
              isVoided && acc.push(parcelNumber);
              return acc;
            },
            []
          );
          try {
            overlay.show();
            await unvoidParcels(shipment.shipmentId, {
              parcelNumber,
            });
            await reloadFn(shipment.shipmentId);

            const parcelNumbersString = parcelNumber.join(", ");

            const successPromptContent =
              parcelNumber.length > 1
                ? {
                    header: S.PARCELS_UNVOIDED,
                    message: formatMessage(
                      S.$_PARCELS_UNVOIDED_MESSAGE,
                      parcelNumbersString
                    ),
                  }
                : {
                    header: S.PARCEL_UNVOIDED,
                    message: formatMessage(
                      S.$_PARCEL_UNVOIDED_MESSAGE,
                      parcelNumber
                    ),
                  };

            prompt.showInfo(successPromptContent);
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.UNVOID_PARCELS }
      ),
    onClickUnvoid: ({ overlay, prompt, reloadFn, notifier, unvoidShipment }) =>
      notifier.runAsync(
        async shipment => {
          try {
            overlay.show();

            await unvoidShipment(shipment.shipmentId);
            await reloadFn(shipment.shipmentId);

            prompt.showInfo({
              header: S.SHIPMENT_UNVOIDED,
              message: formatMessage(
                S.$_SHIPMENT_UNVOIDED_MESSAGE,
                get(shipment, "outboundConsignment.consignmentNumber")
              ),
            });
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onClickDelete: ({ notifier, prompt, reloadFn, overlay, deleteShipment }) =>
      notifier.runAsync(
        async shipment => {
          const consignmentNumber = get(
            shipment,
            "outboundConsignment.consignmentNumber"
          );

          try {
            overlay.show();

            await deleteShipment(shipment.shipmentId);
            await reloadFn(shipment.shipmentId, {
              message: S.SHIPMENT_NOT_FOUND,
            });

            prompt.showInfo({
              header: S.SHIPMENT_DELETED,
              message: formatMessage(
                S.$_SHIPMENT_DELETED_MESSAGE,
                consignmentNumber
              ),
            });
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onClickChangeDate: ({
      notifier,
      prompt,
      reloadFn,
      overlay,
      changeShipmentDate,
    }) =>
      notifier.runAsync(
        async (shipment, date, onSuccess = noop) => {
          try {
            overlay.show();

            await changeShipmentDate(shipment.shipmentId, formatISODate(date));
            await reloadFn(shipment.shipmentId);

            onSuccess();

            const consignmentNumber = get(
              shipment,
              "outboundConsignment.consignmentNumber"
            );

            prompt.showInfo({
              header: S.SHIPMENT_DATE_CHANGED,
              message: formatMessage(
                S.$_SHIPMENT_DATE_CHANGED_MESSAGE_TO_$,
                consignmentNumber,
                date
              ),
            });
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    onShipmentPrint: ({ printWithInvoice, reloadFn, notifier, overlay }) =>
      overlay.showWhile(
        notifier.runAsync(
          async selectedShipment => {
            await printWithInvoice([selectedShipment], true);
            reloadFn(selectedShipment.shipmentId);
          },
          { entityName: S.SHIPMENT }
        )
      ),
  })
);

import get from "lodash/get";
import moment from "moment";

import { SHIPMENT_TYPES } from "@dpdgroupuk/mydpd-enums";

import * as DATE_FORMAT from "~/constants/dateFormats";
import * as S from "~/constants/strings";
import {
  CustomsModels,
  InvoiceModels,
  LiabilityModels,
  ParcelModels,
  ServiceModels,
  ShipmentModels,
} from "~/models";
import { pipe } from "~/utils/function";
import { toUppercaseValues } from "~/utils/object";

export const setCopyShipmentDate = data => {
  const { shipment, enableWeekend, storageDate } = data;
  const minDate = ShipmentModels.getMinShipmentDate(enableWeekend);
  const shipmentDate = moment(
    storageDate,
    DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT
  ).isAfter(moment(minDate, DATE_FORMAT.ISO_DATE_FORMAT))
    ? storageDate
    : minDate;

  return {
    ...data,
    shipment: {
      ...shipment,
      shipmentDate,
    },
  };
};

export const getCopyShipmentInitialDataValues = data => {
  const { shipment } = pipe(
    ShipmentModels.setupBasicShipment,
    ShipmentModels.setupInitialGenerateCustomsData,
    CustomsModels.setupInitialCustomsFields,
    InvoiceModels.setupInitialInvoice,
    ServiceModels.setupInitialInboundService,
    LiabilityModels.setupInitialExtendedLiability,
    ShipmentModels.setupGstFields,
    ParcelModels.setupInitialParcels,
    ShipmentModels.setupReturnShipmentType,
    setCopyShipmentDate,
    ShipmentModels.setUniqueShippingRef1
  )(data);

  return shipment;
};

export const getCreateShipmentInitialValues = (
  profile = {},
  countries,
  preferences,
  preferredDate,
  shippingSettings,
  uniqueSenderRef1
) => {
  const countryCode = get(preferences, "shippingDefaults.intCountry") || S.GB;
  const { defaultInformation, useMyDpdAccountSettings, profileCode } = profile;
  const shipmentDate = ShipmentModels.getPreferredShipmentDate(
    preferredDate,
    get(preferences, "shippingDefaults.enableWeekend"),
    get(preferences, "shippingDefaults.defaultShipmentDate")
  );

  return toUppercaseValues({
    shipmentDate,
    shipmentType: SHIPMENT_TYPES.NO_TYPE.toString(),
    generateCustomsData: null,
    profileCode,
    outboundConsignment: {
      deliveryInstructions:
        (countryCode && !useMyDpdAccountSettings && defaultInformation) || "",
      deliveryDetails: {
        address: {
          ...(countries.length !== 0 ? { countryCode } : ""),
        },
      },
      totalWeight: ShipmentModels.getDefaultTotalWeight(
        profile,
        preferences,
        countryCode,
        "" // unnecessary networkСode because it uses for clearing page and initial data for Create page
      ),
      numberOfParcels: ShipmentModels.getDefaultNumberOfParcels(
        preferences,
        countryCode
      ),
      shippingRef1: ShipmentModels.getDefaultUniqueShippingRef1(
        countryCode,
        uniqueSenderRef1,
        { preferences, shippingSettings }
      ),
    },
  });
};

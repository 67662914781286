import api from "./api";
import { SHIPPING_MANIFESTS } from "./basePaths";

export const getManifests = query =>
  api.get({
    path: SHIPPING_MANIFESTS,
    query,
  });

export const createManifest = query =>
  api.post({
    path: SHIPPING_MANIFESTS,
    query,
  });

export const getManifestById = manifestId =>
  api.get({
    path: `${SHIPPING_MANIFESTS}/${manifestId}`,
  });

export const INVOICE_TYPE = {
  PROFORMA: 1,
  COMMERCIAL: 2,
};
export const INVOICE_TYPE_STRING = {
  PROFORMA: "P",
  COMMERCIAL: "C",
};
export const invoiceTypes = [
  {
    value: INVOICE_TYPE.PROFORMA,
    label: "Proforma",
  },
  {
    value: INVOICE_TYPE.COMMERCIAL,
    label: "Commercial",
  },
];
export const excludeReasons = [
  "1^19",
  "2^19",
  "1^79",
  "2^79",
  "1^39",
  "2^39",
  "1^89",
  "2^89",
];
export const defaultReasons = [
  {
    label: "Sale",
    value: "01",
  },
  {
    label: "Gift",
    value: "03",
  },
];
export const deliveredAtPlace = {
  label: "Delivered at Place",
  value: "DAP",
};
export const dutiesTaxesPrepaid = {
  label: "Duties & Taxes Prepaid",
  value: "DT1",
};

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import withHandlers from "recompose/withHandlers";

import { ShipmentEntity } from "~/constants/forms";
import { getEditShipmentInitialDataValues } from "~/pages/ShipmentReview/pages/ShipmentEdit/models";

export default withHandlers({
  loadEditShipment:
    ({
      customerPrefs,
      fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef,
      fetchAndProcessServices,
      initializeShipment,
      runCheckPostcodeIssues,
      initWithShipmentDate,
      fetchNetworks,
      customer,
      preferences,
      securitySettings,
      storageDate,
      authUser,
    }) =>
    async shipment => {
      try {
        await fetchNetworks({
          networkCode: shipment.outboundConsignment.networkCode,
        });
      } catch (e) {}

      const { countries, profiles, currencies, exportReasons } =
        await fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef({
          isAvailableInvoice: !isEmpty(shipment.invoice),
        });
      const profile = profiles.find(
        ({ profileCode }) => profileCode === shipment?.profileCode
      );
      const activeProfile = profile || profiles[0];
      const deliveryCountry =
        countries.find(
          ({ countryKey }) =>
            countryKey ===
            get(
              shipment,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                .COUNTRY_CODE
            )
        ) || {};

      let outboundNetworks;
      let inboundNetworks;
      let selectedOutboundNetwork;
      let outboundErrors;
      let inboundErrors;

      // NOTE: Prevents service calls for voided shipment on Scanning page
      if (!shipment.isVoided) {
        const {
          outboundNetworks: outboundNetworksData,
          inboundNetworks: inboundNetworksData,
          selectedOutboundNetwork: selectedOutboundNetworkData,
          outboundErrors: outboundErrorsData,
          inboundErrors: inboundErrorsData,
        } = await fetchAndProcessServices({
          shipment,
          profile: activeProfile,
          selectedCountry: deliveryCountry,
        });

        outboundNetworks = outboundNetworksData;
        inboundNetworks = inboundNetworksData;
        selectedOutboundNetwork = selectedOutboundNetworkData;
        outboundErrors = outboundErrorsData;
        inboundErrors = inboundErrorsData;
      } else {
        const {
          outboundNetworks: outboundNetworksData,
          selectedOutboundNetwork: selectedOutboundNetworkData,
        } = await fetchAndProcessServices({
          shipment: {
            ...shipment,
            outboundConsignment: {
              ...shipment.outboundConsignment,
              numberOfParcels: 1,
              totalWeight: 1,
            },
          },
          profile: activeProfile,
          selectedCountry: deliveryCountry,
        });

        outboundNetworks = outboundNetworksData;
        selectedOutboundNetwork = selectedOutboundNetworkData;
      }

      const initialValues = getEditShipmentInitialDataValues({
        exportReasons,
        outboundNetworks,
        selectedOutboundNetwork,
        inboundNetworks,
        customer,
        preferences,
        customerPrefs,
        profile: activeProfile,
        shipment,
        securitySettings,
        currencies,
        countries,
        storageDate,
        authUser,
      });

      await initializeShipment({
        shipment,
        inboundNetworks,
        selectedOutboundNetwork,
        outboundErrors,
        inboundErrors,
        initialValues,
      });

      runCheckPostcodeIssues();
      initWithShipmentDate();
    },
});

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

import { UmsSelectors } from "~/redux";

const ProtectedRoute = ({
  component: Component,
  redirectTo,
  isEnabled,
  ...rest
}) => {
  const securitySettings = useSelector(UmsSelectors.getSecuritySettings); // TODO
  const shippingSettings = useSelector(UmsSelectors.getShippingSettings);
  const customerServices = useSelector(UmsSelectors.getCustomerServices);

  return (
    <Route
      {...rest}
      render={props =>
        isEnabled({ securitySettings, shippingSettings, customerServices }) ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
  redirectTo: PropTypes.string,
  isEnabled: PropTypes.func,
};

export default ProtectedRoute;

import api from "~/apis/api";
import { SHIPPING_LABELS } from "~/apis/basePaths";

export const getLabelsByShipmentIds = query =>
  api.get({
    path: `${SHIPPING_LABELS}/`,
    query,
  });

export const getUnprintedLabels = query =>
  api.get({
    path: `${SHIPPING_LABELS}/unprinted`,
    query,
  });

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { profilesApi } from "~/apis";

export const ActionTypes = createActionTypes("PROFILES", {
  FETCH: createAsyncActionTypes("FETCH"),
});

export const fetchProfiles = createAsyncAction(
  profilesApi.fetchProfiles,
  ActionTypes.FETCH
);

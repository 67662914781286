import { ReferenceActionTypes } from "./actions";

export const initialState = {
  countries: [],
  activeOutboundService: undefined,
  activeInboundService: undefined,
  outboundServices: [],
  inboundServices: [],
  exportReasons: [],
  currencies: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ReferenceActionTypes.FETCH_COUNTRIES.SUCCESS: {
      return {
        ...state,
        countries: action.payload.data,
      };
    }
    case ReferenceActionTypes.FETCH_EXPORT_REASONS.SUCCESS: {
      return {
        ...state,
        exportReasons: action.payload.data,
      };
    }
    case ReferenceActionTypes.FETCH_OUTBOUND_SERVICES.SUCCESS: {
      return {
        ...state,
        outboundServices: action.payload.data,
      };
    }
    case ReferenceActionTypes.FETCH_OUTBOUND_SERVICES.FAILURE: {
      return {
        ...state,
        outboundServices: [],
      };
    }
    case ReferenceActionTypes.FETCH_INBOUND_SERVICES.SUCCESS: {
      return {
        ...state,
        inboundServices: action.payload.data,
      };
    }
    case ReferenceActionTypes.FETCH_INBOUND_SERVICES.FAILURE: {
      return {
        ...state,
        inboundServices: initialState.inboundServices,
      };
    }
    case ReferenceActionTypes.FETCH_CURRENCIES.SUCCESS: {
      return {
        ...state,
        currencies: action.payload.data,
      };
    }
    case ReferenceActionTypes.CLEAR_INBOUND_SERVICES: {
      return {
        ...state,
        inboundServices: initialState.inboundServices,
        activeInboundService: initialState.activeInboundService,
      };
    }
    case ReferenceActionTypes.CLEAR_SERVICES: {
      return {
        ...initialState,
        countries: state.countries,
        currencies: state.currencies,
        exportReasons: state.exportReasons,
      };
    }
    case ReferenceActionTypes.CLEAR_EXPORT_REASONS: {
      return {
        ...state,
        exportReasons: initialState.exportReasons,
      };
    }
    case ReferenceActionTypes.SET_ACTIVE_OUTBOUND_SERVICE: {
      return {
        ...state,
        activeOutboundService: action.payload,
      };
    }
    case ReferenceActionTypes.SET_ACTIVE_INBOUND_SERVICE: {
      return {
        ...state,
        activeInboundService: action.payload,
      };
    }
    case ReferenceActionTypes.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
};

import get from "lodash/get";
import moment from "moment";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";

import {
  UserDataActions,
  UserDataSelectors,
  withAppState,
  withAppUserPreferences,
  withUrlResolver,
} from "@dpdgroupuk/mydpd-app";
import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

import { getCollectionRequestStatus } from "~/apis/collection";
import {
  DAY_DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  ISO_DATE_FORMAT,
} from "~/constants/dateFormats";
import { ShipmentEntity } from "~/constants/forms";
import {
  ARRANGE_COLLECTION_$,
  ARRANGE_COLLECTION_FOR_WEEKEND,
} from "~/constants/strings";
import { ShipmentModels } from "~/models";
import { isWeekend } from "~/utils/date";
import { getValue } from "~/utils/object";
import { formatMessage } from "~/utils/string";

export default compose(
  withAppState,
  withAppUserPreferences,
  withUrlResolver,
  withState("rememberDate", "setRememberDate", false),
  withState("collectionRequestBannerId", "setCollectionRequestBannerId"),
  connect(state => ({
    rememberedDate: UserDataSelectors?.getItem(state.app, "date"),
    requestedDates:
      UserDataSelectors?.getItem(state.app, "requestedDates") || [],
  })),
  withHandlers({
    validateCollectionDate: props =>
      props.notifier.runAsync(
        props.showInvisibleFieldError(
          async shipmentDate => {
            if (props.collectionRequestBannerId) {
              props.banner.hideById(props.collectionRequestBannerId);
            }

            const autoCollectEnabled = getValue(
              props.preferences,
              "prefsCollectionDefaults.autoCollectEnabled",
              false
            );

            if (!autoCollectEnabled) {
              const requestedDates = props.requestedDates;
              if (shipmentDate && !requestedDates.includes(shipmentDate)) {
                const collectionDate = moment(
                  shipmentDate,
                  DEFAULT_DATE_FORMAT
                ).format(ISO_DATE_FORMAT);

                const collectionPostcode = get(
                  props.collectionDetails,
                  "address.postcode"
                );

                if (!collectionPostcode) return;

                const result = await getCollectionRequestStatus(
                  {
                    collectionDate,
                    collectionPostcode,
                  },
                  {
                    signal: props.abortController.signal,
                  }
                );

                const isRequested = getValue(result, "data.isRequested", false);

                if (!isRequested) {
                  const isWeekendDay = isWeekend(new Date(collectionDate));
                  const message = isWeekendDay
                    ? ARRANGE_COLLECTION_FOR_WEEKEND
                    : formatMessage(
                        ARRANGE_COLLECTION_$,
                        moment(shipmentDate, DAY_DEFAULT_DATE_FORMAT).format(
                          DEFAULT_DATE_FORMAT
                        )
                      );
                  const collectionsUrl =
                    props.urlResolver.getUrl("collections.create");
                  const actions = isWeekendDay
                    ? []
                    : [
                        {
                          text: "click here",
                          href: collectionsUrl,
                          target: "_blank",
                        },
                      ];
                  const bannerId = props.banner.showByType(BANNERS_TYPES.INFO, {
                    message,
                    closable: true,
                    showIcon: true,
                    actions,
                  });
                  bannerId && props.setCollectionRequestBannerId(bannerId);
                }

                props.dispatch(
                  UserDataActions.setItem("requestedDates", [
                    ...requestedDates,
                    shipmentDate,
                  ])
                );
              }
            }
          },
          { uiFields: props.uiFields }
        ),
        { entityName: "Collection" }
      ),
  }),
  withHandlers({
    initWithShipmentDate: props => () => {
      const rememberedDate = props.rememberedDate;
      const shipmentDate = get(
        props.currentValues,
        ShipmentEntity.SHIPMENT_DATE
      );
      const preferredDate = ShipmentModels.getPreferredShipmentDate(
        rememberedDate,
        get(props.preferences, "shippingDefaults.enableWeekend"),
        get(props.preferences, "shippingDefaults.defaultShipmentDate")
      );

      const date = shipmentDate || preferredDate;

      props.setRememberDate(!!rememberedDate);

      props.validateCollectionDate(date.toUpperCase());
      if (rememberedDate) {
        props.dispatch(UserDataActions.setItem("date", date));
      }
    },
    onRememberDateChange: props => e => {
      const value = e.target.checked;
      props.setRememberDate(value);
      if (!value) {
        props.dispatch(UserDataActions.clearItem("date"));
      } else if (value && props.createShipmentValues.shipmentDate) {
        props.dispatch(
          UserDataActions.setItem(
            "date",
            props.createShipmentValues.shipmentDate
          )
        );
      }
    },
    onDateChange: props => (e, t, value) => {
      props.validateCollectionDate(value.toUpperCase());

      if (props.rememberDate) {
        props.dispatch(UserDataActions.setItem("date", value));
      }
    },
  }),
  lifecycle({
    componentWillUnmount() {
      if (this.props.collectionRequestBannerId) {
        this.props.banner.hideById(this.props.collectionRequestBannerId);
        this.props.setCollectionRequestBannerId();
      }
    },
  })
);

import { get } from "lodash-es";
import { getFormSyncErrors } from "redux-form";
import { createSelector } from "reselect";

import { SHIPMENT_REVIEW_SEARCH_FORM } from "~/constants/forms";
import { getFormState } from "~/pages/Shipment/redux/selectors";
import { getValue } from "~/utils/object";

export const getShipmentList = state =>
  get(state, "ui.review.shipmentList.data", {});

export const getChangeDateField = createSelector(getFormState, state =>
  Object.keys(getValue(state, "registeredFields", {}))
);

export const getShipmentSearchFormSyncErrors = state =>
  getFormSyncErrors(SHIPMENT_REVIEW_SEARCH_FORM)(state) || {};

export const getSelectedShipment = state =>
  get(state, "ui.review.selectedShipment.data", {});

export const getIsSelectedShipmentLoading = state =>
  get(state, "ui.review.selectedShipment.isLoading", false);

import { isAbortError } from "@dpdgroupuk/fetch-client";

import { Fields, SEARCH_CRITERIA } from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import {
  FAILED_TO_FETCH,
  FAILED_TO_FETCH_ERROR_MESSAGE_$,
  SOMETHING_WENT_WRONG,
} from "~/constants/strings";

import { formatMessage } from "./string";

export const getHttpErrorMessage = e => {
  if (Array.isArray(e.errors)) {
    const firstError = e.errors[0];
    return firstError.message;
  }

  if (e.errors && e.errors.message) {
    return e.errors.message;
  }
  return e.message;
};

export const getErrorMessage = (e, failedToFetchEntityName) => {
  if (e.name === "HttpClientError") {
    return getHttpErrorMessage(e);
  }

  if (e.message) {
    if (e.message && e.message.includes(FAILED_TO_FETCH)) {
      return formatMessage(
        FAILED_TO_FETCH_ERROR_MESSAGE_$,
        failedToFetchEntityName
      );
    }

    return e.message;
  }

  return SOMETHING_WENT_WRONG;
};

export const getErrorBody = (...args) => ({
  message: getErrorMessage(...args),
  displayTime: SHOW_ALERT_DISPLAY_TIME,
});

export const isUnauthorizedUserError = err => err.statusCode === 401;

export const isIgnoredError = err =>
  isAbortError(err) || isUnauthorizedUserError(err);

export const searchCriteriaFields = [
  Fields.SHORT_NAME,
  Fields.ORGANISATION,
  Fields.POSTCODE,
  SEARCH_CRITERIA,
];

export const getInvisibleFieldErrorMessages = (errors = [], fields = []) => {
  if (Array.isArray(errors)) {
    return errors.reduce((acc, err) => {
      if (!fields.includes(err.fieldPath)) {
        acc.push(err.message);
      }

      return acc;
    }, []);
  } else {
    return [];
  }
};

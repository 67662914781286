import {
  CustomsModels,
  InvoiceModels,
  LiabilityModels,
  ParcelModels,
  ServiceModels,
  ShipmentModels,
} from "~/models";
import { pipe } from "~/utils/function";

export const getFailedShipmentInitialDataValues = data => {
  const { shipment } = pipe(
    ShipmentModels.setupBasicShipment,
    ShipmentModels.setupInitialGenerateCustomsData,
    CustomsModels.setupInitialCustomsFields,
    InvoiceModels.setupInitialInvoice,
    ServiceModels.setupInitialInboundService,
    LiabilityModels.setupInitialExtendedLiability,
    ShipmentModels.setupGstFields,
    ParcelModels.setupInitialParcels,
    ShipmentModels.setupReturnShipmentType,
    ShipmentModels.setEditShipmentDate,
    ShipmentModels.setUniqueShippingRef1
  )(data);

  return shipment;
};

export const isAvailableCsvService = (networkCode, serviceExclusions) =>
  networkCode && !serviceExclusions.includes(networkCode);

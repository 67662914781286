import get from "lodash/get";
import set from "lodash/set";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { autofill } from "redux-form";

import { SHIPMENT_TYPES } from "@dpdgroupuk/mydpd-enums";

import { getSearchAddressDetails } from "~/components/SearchAddress/selectors";
import {
  FilterFields,
  getInboundCountry,
  getOutboundCountry,
  INBOUND_CONSIGNMENT,
  OUTBOUND_CONSIGNMENT,
  ShipmentEntity,
} from "~/constants/forms";
import { ADDRESS_BOOK_ENTITY, returnCountries } from "~/constants/strings";
import { ServiceModels, ShipmentModels } from "~/models";
import { ShipmentActions, ShipmentSelectors } from "~/pages/Shipment/redux";
import { AddressBookActions } from "~/redux";
import { getValue, toUppercaseValues } from "~/utils/object";
import { touchFields, untouchEmptyFields } from "~/utils/reduxForm";

export default ({ formSection, searchFormName, addressBookType }) =>
  compose(
    connect(
      state => ({
        searchFormValues: getSearchAddressDetails(searchFormName)(state),
        selectedReturnAddressBook:
          ShipmentSelectors.getSelectedReturnAddressBook(state),
      }),
      (dispatch, { reInitializeForm, pageConfig }) => ({
        setSelectedAddressBook: (addressBook, formSection) =>
          formSection === INBOUND_CONSIGNMENT
            ? dispatch(
                ShipmentActions.setSelectedReturnAddressBook(addressBook)
              )
            : dispatch(ShipmentActions.setSelectedAddressBook(addressBook)),
        updateForm: values => {
          reInitializeForm(values);
          dispatch(touchFields(pageConfig.formName, values));
          dispatch(untouchEmptyFields(pageConfig.formName, values));
        },
        updateSearchValue: (shortName, address, searchCriteriaField) =>
          dispatch(
            autofill(
              searchFormName,
              FilterFields.SEARCH_CRITERIA_VALUE,
              get(
                {
                  shortName,
                  postcode: address.postcode,
                  organisation: address.organisation,
                },
                searchCriteriaField,
                shortName
              )
            )
          ),
        updateImporterVatEoriPid: () =>
          dispatch(ShipmentActions.updateImporterVatEoriPid(pageConfig)),
      })
    ),
    withHandlers({
      onAddressBookSelectionChange: ({
        updateSearchValue,
        profile,
        createShipmentValues,
        setSelectedAddressBook,
        updateForm,
        searchFormValues,
        notifier,
        dispatch,
        countries,
        selectedAddressBook,
        selectedReturnAddressBook,
        abortController,
        updateImporterVatEoriPid,
        updateImporterDetails,
        disabledFields,
        preferences,
        shippingSettings,
        uniqueSenderRef1,
        validateOnUniqueShipmentRef1,
      }) =>
        notifier.runAsync(
          async ({ addressBookId }) => {
            // @see:https://it.dpduk.live/version/customer-shipping/sprint-2.9/diag_yJmlZR6C48kIGIP1.html
            const savedAddressBookId =
              formSection === OUTBOUND_CONSIGNMENT
                ? getValue(selectedAddressBook, "addressBookId", "")
                : getValue(selectedReturnAddressBook, "addressBookId", "");
            const { data } =
              addressBookId !== savedAddressBookId
                ? await dispatch(
                    AddressBookActions.fetchAddressBookById(
                      addressBookId,
                      {
                        addressBookType,
                      },
                      {
                        signal: abortController.signal,
                      }
                    )
                  )
                : formSection === OUTBOUND_CONSIGNMENT
                ? { data: selectedAddressBook }
                : { data: selectedReturnAddressBook };
            const countryCode = get(data, "address.countryCode");

            let countryIndexByCode = -1;

            if (countryCode) {
              countryIndexByCode = countries.findIndex(
                ({ value }) => value === countryCode
              );
            }

            const addressBook = {
              ...data,
              address: {
                ...data.address,
                countryCode: countryIndexByCode === -1 ? "" : countryCode,
              },
            };
            const { address, shortName } = addressBook;
            const { searchCriteriaField } = searchFormValues;
            const updatedFormValues = toUppercaseValues({
              ...createShipmentValues,
              ...ShipmentModels.updateCreateShipmentFormFields({
                formSection,
                profile,
                ...addressBook,
              }),
            });

            // NOTE: must round totalWeight because country will be able to change
            // @see:https://it.dpduk.live/version/customer-shipping/sprint-2.9/diag_TCEVcL6GAqAAhT8w.html?id=1671529257889
            set(
              updatedFormValues,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
              ServiceModels.roundTotalWeight(
                get(
                  createShipmentValues,
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT
                ),
                getValue(
                  createShipmentValues,
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
                  ""
                ),
                countryCode
              )
            );

            if (
              !returnCountries.includes(getOutboundCountry(updatedFormValues))
            ) {
              updatedFormValues.shipmentType =
                SHIPMENT_TYPES.NO_TYPE.toString();
            }

            if (
              formSection === OUTBOUND_CONSIGNMENT &&
              disabledFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
              ] &&
              !get(
                updatedFormValues,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
              )
            ) {
              const defaultRef1 = ShipmentModels.getDefaultUniqueShippingRef1(
                countryCode,
                uniqueSenderRef1,
                { preferences, shippingSettings }
              );
              set(
                updatedFormValues,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
                defaultRef1
              );
            }

            if (
              formSection === OUTBOUND_CONSIGNMENT ||
              returnCountries.includes(getInboundCountry(updatedFormValues))
            ) {
              setSelectedAddressBook(
                toUppercaseValues(addressBook),
                formSection
              );
              updateSearchValue(shortName, address, searchCriteriaField);
              updateForm(toUppercaseValues(updatedFormValues));

              if (formSection === OUTBOUND_CONSIGNMENT) {
                validateOnUniqueShipmentRef1(
                  getValue(
                    updatedFormValues,
                    ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
                    getValue(
                      createShipmentValues,
                      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
                    )
                  )
                ).catch(() => {
                  // ignore error because it's shown in the popup inside validateOnUniqueShipmentRef1
                });
              }

              if (
                formSection === OUTBOUND_CONSIGNMENT &&
                createShipmentValues.generateCustomsData
              ) {
                updateImporterVatEoriPid();
                updateImporterDetails();
              }
            }
          },
          { entityName: ADDRESS_BOOK_ENTITY }
        ),
    })
  );

import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import { Field, getFormValues, reduxForm, stopSubmit } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { ADD_EXTRA_LABEL_FORM, AddExtraLabelEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers, ShipmentModels } from "~/models";
import {
  returnTotalWeightValidation,
  submissionErrorFirst,
  totalNewParcelsValidation,
} from "~/models/validators/additionalValidations";
import extraLabelSchema from "~/models/validators/extraLabelSchema";
import { ShipmentActions, ShipmentSelectors } from "~/pages/Shipment/redux";
import createValidator from "~/utils/joiReduxForm";
import { flatPathToObject } from "~/utils/object";

import Modal from "../ShipmentReviewModal";

const AddExtraLabelModal = ({
  open,
  onCancel,
  onSubmit,
  handleSubmit,
  selectedCountry,
  ...props
}) => (
  <Modal
    open={open}
    onCancel={onCancel}
    title={S.ADD_EXTRA_LABEL}
    saveButton={{ visible: true, text: S.OK }}
    onSave={handleSubmit(onSubmit)}
    size="md"
  >
    <Container className="p-0">
      <Row>
        <Col>
          <Field
            minLength={1}
            maxLength={2}
            normalize={Normalizers.integerNormalize}
            component={FormControl.Input}
            label={S.TOTAL_NEW_PARCELS}
            name={AddExtraLabelEntity.TOTAL_NEW_PARCELS}
            onBlur={props.onFieldEntry}
            required
          />
          <Field
            normalize={Normalizers.floatNormalize}
            minLength={1}
            maxLength={5}
            component={FormControl.Input}
            label={S.TOTAL_WEIGHT_OF_NEW_PARCELS}
            name={AddExtraLabelEntity.TOTAL_NEW_PARCELS_WEIGHT}
            helperText={ShipmentModels.getRoundedHelperText(selectedCountry)}
            onBlur={props.onFieldEntry}
            required
          />
        </Col>
      </Row>
    </Container>
  </Modal>
);

AddExtraLabelModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  selectedCountry: PropTypes.object,
  onFieldEntry: PropTypes.func,
  handleSubmit: PropTypes.func,
  numberOfParcels: PropTypes.number.isRequired,
};

export default compose(
  connect(
    state => ({
      form: ADD_EXTRA_LABEL_FORM,
      currentValues: getFormValues(ADD_EXTRA_LABEL_FORM)(state),
      createShipmentSyncErrors: ShipmentSelectors.getShipmentFormSyncErrors(
        state,
        { formName: ADD_EXTRA_LABEL_FORM }
      ),
    }),
    (dispatch, { currentValues }) => ({
      touchAddExtraLabelForm: errors =>
        dispatch(
          ShipmentActions.touchShipmentFormFields(
            ADD_EXTRA_LABEL_FORM,
            Object.keys(errors),
            currentValues
          )
        ),
      stopSubmitAddExtraLabelForm: errors =>
        dispatch(stopSubmit(ADD_EXTRA_LABEL_FORM, flatPathToObject(errors))),
    })
  ),
  withProps(({ selectedCountry }) => ({
    initialValues: {
      countryCode: selectedCountry?.countryKey,
    },
  })),
  reduxForm({
    form: ADD_EXTRA_LABEL_FORM,
    shouldError: () => true,
    validate: (values, props) =>
      createValidator(extraLabelSchema, [
        joiValidationErrors =>
          returnTotalWeightValidation(props, joiValidationErrors),
        joiValidationErrors => submissionErrorFirst(props, joiValidationErrors),
        () =>
          totalNewParcelsValidation(
            props,
            AddExtraLabelEntity.TOTAL_NEW_PARCELS
          ),
      ])(values, props),
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (!this.props.open && prevProps.open) {
        this.props.reset();
      }
    },
  })
)(AddExtraLabelModal);

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { addressBookApi } from "~/apis";
import { ADDRESS_BOOK_TYPES } from "~/constants/strings";

export const AddressBookActionTypes = createActionTypes("ADDRESS_BOOK", {
  FETCH: createAsyncActionTypes("FETCH"),
  FETCH_DEFAULT_ADDRESS_BOOK: createAsyncActionTypes(
    "FETCH_DEFAULT_ADDRESS_BOOK"
  ),
  CLEAR_ADDRESS_BOOK: "CLEAR_ADDRESS_BOOK",
  FETCH_ADDRESS_BOOK_BY_ID: createAsyncActionTypes("FETCH_ADDRESS_BOOK_BY_ID"),
});

export const fetchAddressBooks = createAsyncAction(
  (query, fetchOptions) =>
    addressBookApi.fetchAddressBooks(query, fetchOptions),
  AddressBookActionTypes.FETCH
);

export const fetchDefaultReturnAddressBook = createAsyncAction(
  async fetchOptions => {
    const { data } = await addressBookApi.fetchDefaultReturnAddressBook(
      { type: ADDRESS_BOOK_TYPES.RETURN },
      fetchOptions
    );
    return data;
  },
  AddressBookActionTypes.FETCH_DEFAULT_ADDRESS_BOOK
);

export const fetchAddressBookById = createAsyncAction(
  (id, query, options) =>
    addressBookApi.fetchAddressBookById(id, query, options),
  AddressBookActionTypes.FETCH_ADDRESS_BOOK_BY_ID
);

export const clearAddressBook = () => ({
  type: AddressBookActionTypes.CLEAR_ADDRESS_BOOK,
});

import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";

import {
  ImportsFields,
  ProductEntity,
  ShipmentEntity,
} from "~/constants/forms";

const ignoredFields = [
  ImportsFields.TEMPLATE_NAME,
  ProductEntity.PRODUCT_URL,
  ShipmentEntity.PROFILE_CODE,
];

export default (state, action) => {
  switch (action.type) {
    case "@@redux-form/BLUR":
    case "@@redux-form/AUTOFILL":
      if (
        typeof action.payload === "string" &&
        !ignoredFields.includes(action.meta.field)
      ) {
        return {
          ...state,
          values: set(
            cloneDeep(state.values),
            action.meta.field,
            action.payload.toUpperCase()
          ),
        };
      }
      return state;
    default:
      return state;
  }
};

import {
  CustomsModels,
  InvoiceModels,
  LiabilityModels,
  ParcelModels,
  ServiceModels,
  ShipmentModels,
} from "~/models";
import { pipe } from "~/utils/function";

export const getEditShipmentInitialDataValues = data => {
  const { shipment } = pipe(
    ShipmentModels.setupBasicShipment,
    ShipmentModels.setupInitialGenerateCustomsData,
    CustomsModels.setupInitialCustomsFields,
    InvoiceModels.setupInitialInvoice,
    ServiceModels.setupInitialInboundService,
    LiabilityModels.setupInitialExtendedLiability,
    ShipmentModels.setupGstFields,
    ParcelModels.setupInitialParcelsWithIds,
    ShipmentModels.setupReturnShipmentType,
    ShipmentModels.setEditShipmentDate
  )(data);

  return shipment;
};

import api from "./api";
import { ADDRESSBOOK } from "./basePaths";

export const fetchAddressBooks = (query, options) =>
  api.get({
    path: `${ADDRESSBOOK}/filter`,
    query,
    options,
  });

export const fetchDefaultReturnAddressBook = (query, options) =>
  api.get({
    path: `${ADDRESSBOOK}/default`,
    query,
    options,
  });

export const insertAddressBook = (body, options) =>
  api.post({
    path: ADDRESSBOOK,
    body,
    options,
  });

export const updateAddressBook = (addressBookId, query, body, options) =>
  api.put({
    path: `${ADDRESSBOOK}/${addressBookId}`,
    query,
    body,
    options,
  });

export const fetchAddressBookById = (id, query, options) =>
  api.get({
    path: `${ADDRESSBOOK}/${id}`,
    query,
    options,
  });

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import get from "lodash/get";
import moment from "moment";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";

import { FormControl, Step } from "@dpdgroupuk/mydpd-ui";

import Checkbox from "~/components/Checkbox";
import Customs from "~/components/Customs";
import DomesticService from "~/components/DomesticService";
import ExtendedLiability from "~/components/ExtendedLiability";
import { DAY_DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import {
  Fields,
  OUTBOUND_CONSIGNMENT_UPPERCASE,
  PACKAGE_DETAILS,
  ShipmentEntity,
} from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import { Normalizers, ShipmentModels } from "~/models";
import { isWeekend } from "~/utils/date";
import { formatMessage } from "~/utils/string";

const PackageDetails = props => {
  const {
    createShipmentSyncErrors,
    createShipmentSubmitErrors,
    requiredFields,
    currencies,
    onFieldEntry,
    changeLiabilityValue,
    disabledFields,
    allowedFields,
    preferences,
    onGenerateCustomsDataChange,
    onNetworkChange,
    autofocusTotalPackages,
    onLiabilityChange,
  } = props;
  const enableWeekend = useMemo(
    () => get(preferences, "shippingDefaults.enableWeekend", false),
    [preferences]
  );
  const { minDate, maxDate } = useMemo(
    () => ShipmentModels.getAvailableDateRange(enableWeekend),
    [enableWeekend]
  );

  const [datesIfDayChanges, setDatesIfDayChanges] = useState({
    minDate,
    maxDate,
  });

  useEffect(() => {
    const durationTillNextDay = moment.duration(
      moment().add(1, "day").startOf("day").diff(moment())
    );
    const durationInMilliseconds = durationTillNextDay.asMilliseconds();

    const interval = setInterval(
      () =>
        setDatesIfDayChanges(
          ShipmentModels.getAvailableDateRange(enableWeekend)
        ),
      durationInMilliseconds
    );
    return () => {
      clearInterval(interval);
    };
  }, [datesIfDayChanges]);

  const dropdownRef = useRef(null);

  const selectedCurrency = get(
    props,
    "createShipmentValues.outboundConsignment.currency",
    S.GBP
  );

  const isValidPackageDetailsSection = useMemo(
    () =>
      ShipmentModels.isValidPackageDetailsSection(
        createShipmentSyncErrors,
        createShipmentSubmitErrors
      ),
    [createShipmentSyncErrors, createShipmentSubmitErrors]
  );

  const handleDatePickerBlur = useCallback(
    (...data) => {
      props.onDateChange(...data);
    },
    [props.onDateChange]
  );

  const filterDate = useCallback(
    date => enableWeekend || !isWeekend(date),
    [enableWeekend]
  );

  const handleShipmentTypeChange = useCallback(
    (e, value) => props.onChangeShipmentType(e, value, dropdownRef),
    [props.onChangeShipmentType]
  );

  const totalPackagesInputRef = useRef();

  // @see https://it.dpduk.live/version/customer-shipping/sprint-2.10/diag_WH9qUcGGAqCIa402.html?id=1672219620239
  useEffect(() => {
    if (
      !props.disabledFields[
        ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
      ] &&
      autofocusTotalPackages
    ) {
      totalPackagesInputRef.current.focus();
    }
  }, [
    props.disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS],
    autofocusTotalPackages,
  ]);

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      isLoading={props.isLoadingOutboundServices}
      complete={!props.pristine && isValidPackageDetailsSection}
      title={S.PACKAGE_DETAILS}
      help={help[PACKAGE_DETAILS]}
      helpModalTitle={S.PACKAGE_DETAILS}
    >
      <Row>
        <Col xs={6}>
          <Field
            maxLength={2}
            normalize={Normalizers.integerNormalize}
            component={FormControl.Input}
            label={S.TOTAL_PACKAGES_NUMBER}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS}
            onBlur={props.onFieldEntry}
            required
            helperText
            disabled={
              props.disabledFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
              ]
            }
            inputRef={totalPackagesInputRef}
          />
        </Col>
        <Col xs={6}>
          <Field
            normalize={Normalizers.floatNormalize}
            maxLength={8}
            component={FormControl.Input}
            label={S.TOTAL_WEIGHT}
            helperText={ShipmentModels.getRoundedHelperText(
              props.selectedCountry
            )}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT}
            onBlur={props.onFieldEntry}
            required={
              props.requiredFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT
              ]
            }
            disabled={
              props.disabledFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT
              ]
            }
          />
        </Col>
      </Row>
      {allowedFields.domesticService ? (
        <Field
          component={DomesticService}
          name={ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE}
          shipmentSection={OUTBOUND_CONSIGNMENT_UPPERCASE}
          values={props.outboundServices}
          disabled={disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.PRODUCT]}
          serviceDisabled={
            disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE]
          }
          helperText={formatMessage(S.PLEASE_SELECT_$, S.YOUR_PRODUCT_SERVICE)}
          onBlur={props.onFieldEntry}
          required={
            props.requiredFields[
              ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE
            ]
          }
          textTransform={S.UPPERCASE}
          onChange={onNetworkChange}
        />
      ) : (
        <Row>
          <Col>
            <Field
              component={FormControl.Dropdown}
              label={S.PRODUCT_SERVICE}
              name={ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE}
              values={props.networksKeyValue}
              disabled={
                disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.PRODUCT]
              }
              helperText={formatMessage(
                S.PLEASE_SELECT_$,
                S.YOUR_PRODUCT_SERVICE
              )}
              onBlur={props.onFieldEntry}
              required={
                props.requiredFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE
                ]
              }
              textTransform={S.UPPERCASE}
              onChange={onNetworkChange}
            />
          </Col>
        </Row>
      )}
      {allowedFields.customs && (
        <Customs
          requiredFields={requiredFields}
          currencies={currencies}
          onFieldEntry={onFieldEntry}
          disabledFields={disabledFields}
          allowedFields={allowedFields}
        />
      )}
      {allowedFields.extendedLiability && (
        <ExtendedLiability
          selectedCurrency={selectedCurrency}
          requiredFields={props.requiredFields}
          changeLiabilityValue={changeLiabilityValue}
          onFieldEntry={onFieldEntry}
          allowedFields={allowedFields}
          disabledFields={disabledFields}
          onLiabilityChange={onLiabilityChange}
        />
      )}
      {allowedFields[
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
      ] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.DEST_TAX_ID_REG_NUMBER}
              name={
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
              }
              onBlur={props.onFieldEntry}
              maxLength={45}
              helperText={S.MAX_45_CHARACTERS}
              required={
                props.requiredFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
                ]
              }
              onChange={props.onGstChange}
              disabled={
                disabledFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
                ]
              }
            />
          </Col>
        </Row>
      )}
      {allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Dropdown}
              label={S.GST_VAT_PAID}
              name={ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID}
              values={S.DEFAULT_BOOLEAN_KEY_VALUE}
              onBlur={props.onFieldEntry}
              helperText={S.PLEASE_SELECT}
              required={
                props.requiredFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID
                ]
              }
              textTransform={S.UPPERCASE}
              normalize={Normalizers.booleanOrNullValueNormalize}
              format={Normalizers.booleanOrNullValueFormat}
              disabled={
                disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID]
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={7}>
          <Field
            readOnly
            component={FormControl.DatePicker}
            label={S.SHIPMENT_DATE}
            name={ShipmentEntity.SHIPMENT_DATE}
            helperText={S.FORWARD_SHIPMENT_DATE}
            onBlur={handleDatePickerBlur}
            dateFormat={DAY_DEFAULT_DATE_FORMAT}
            minDate={datesIfDayChanges.minDate}
            maxDate={datesIfDayChanges.maxDate}
            filterDate={filterDate}
            required
            disabled={disabledFields[ShipmentEntity.SHIPMENT_DATE]}
          />
        </Col>
        <Col xs={5}>
          <Checkbox
            checked={props.rememberDate}
            label={S.REMEMBER_DATE}
            helperText={S.LOCK_SHIPMENT_DATE}
            onChange={props.onRememberDateChange}
            onBlur={props.onFieldEntry}
            disabled={disabledFields[Fields.REMEMBER_DATE]}
          />
        </Col>
      </Row>
      {allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.YOUR_REFERENCE_1}
              name={ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1}
              helperText={S.MAX_25_CHARACTERS}
              maxLength={25}
              onBlur={props.onFieldEntry}
              disabled={
                disabledFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
                ]
              }
              required={
                props.requiredFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
                ]
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_2}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            required={
              props.requiredFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2
              ]
            }
            disabled={
              disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_3}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            required={
              props.requiredFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3
              ]
            }
            disabled={
              disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3]
            }
          />
        </Col>
      </Row>
      {allowedFields[ShipmentEntity.SHIPMENT_TYPE] && (
        <Row>
          <Col>
            <Field
              inputRef={dropdownRef}
              component={FormControl.Dropdown}
              label={S.COLLECT_DELIVERY}
              name={ShipmentEntity.SHIPMENT_TYPE}
              values={props.shipmentTypes}
              helperText={formatMessage(S.PLEASE_SELECT_$, S.COLLECTION_TYPE)}
              onBlur={props.onFieldEntry}
              onChange={handleShipmentTypeChange}
              textTransform={S.UPPERCASE}
              disabled={disabledFields[ShipmentEntity.SHIPMENT_TYPE]}
            />
          </Col>
        </Row>
      )}
      {allowedFields[ShipmentEntity.GENERATE_CUSTOMS_DATA] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Dropdown}
              label={S.GENERATE_CUSTOMS_DATA}
              placeholder={S.PLEASE_SELECT}
              name={ShipmentEntity.GENERATE_CUSTOMS_DATA}
              values={S.DEFAULT_BOOLEAN_KEY_VALUE}
              normalize={Normalizers.booleanOrNullValueNormalize}
              format={Normalizers.booleanOrNullValueFormat}
              helperText
              textTransform={S.UPPERCASE}
              disabled={disabledFields[ShipmentEntity.GENERATE_CUSTOMS_DATA]}
              onChange={onGenerateCustomsDataChange}
              required={
                props.requiredFields[ShipmentEntity.GENERATE_CUSTOMS_DATA]
              }
            />
          </Col>
        </Row>
      )}
    </Step>
  );
};

PackageDetails.propTypes = {
  ...propTypes,
  disabledFields: PropTypes.object,
  onGstChange: PropTypes.func.isRequired,
  onLiabilityChange: PropTypes.func.isRequired,
};

export default PackageDetails;
